import React, { Component } from 'react';
import FormLayout from 'layouts/form-layout/index';
import { connect } from 'react-redux';
import { login } from 'store/auth/action';

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => {
            dispatch(login(payload))
        }
    }
}
class VerifyEmail extends Component {
    state = {
        isSuccess: false,
        isFetching: true,
    };

    async componentWillMount() {
        try {
            this.setState({ isFetching: true, isSuccess: false });

            const token = this.props.match.params.token;

            if (!token) throw new Error('Token not found');

            const { userId } = await window.$http.getWithoutHeaders(`/verify-email/${token}`);

            this.setState({ isFetching: false, isSuccess: true });

            const response = await window.$http.postWithoutHeaders('/stealth-login', { userId });

            if(response && response.user && response.user.userRole) {
                this.props.login(response);
                this.props.history.push(`/${response.user.userRole.name}/`);
            }

        } catch (err) {
            this.setState({ isFetching: false, isSuccess: false });
        }
    }

    render() {
        return (
            <FormLayout>
                <form className="verify-email">
                    {/* <span className="login100-form-title pt-1">Verify Email</span> */}
                    <div>
                        <div>
                            {
                                this.state.isFetching === true ?
                                    <h4> Loading..... </h4> :
                                    this.state.isSuccess === false ?
                                        <div>
                                            <div className="success animate text-center">
                                            </div>
                                            <h4 className="text-white text-center mt-3">We've sent a confirmation to your e-mail<br /> for verification.</h4>
                                        </div> :
                                        <div>
                                            <div className="success animate text-center">
                                            </div>
                                            <h4 className="text-white text-center mt-3"> Email Successfully verified! </h4>
                                        </div>
                            }
                        </div>
                    </div>
                </form>
            </FormLayout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
