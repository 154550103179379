import React from 'react'
import { Button } from 'antd';
import DataTable from 'elements/data-table';

const TableView = (props) => {

    const appointments = props.appointments;
    const columns = props.columns;

    return (
        <DataTable
            title="Appointment List"
            data={appointments && appointments.rows ? appointments.rows : []}
            columns={columns}
            debounceSearch={props.debounceSearch}
            totalRecords={appointments && appointments.count ? appointments.count : 0}
            loading={props.loading}
            onChange={props.handleChange}
            notShowSearchAndAdd={true}
            customAction={{
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 85,
                className: 'space-nowrap',
                render: (text, record) => (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Button disabled={record.status !== 'confirmed'} type="link" title="Join Meeting" shape="circle">
                            <a href={`${process.env.REACT_APP_VIDEO_CONFERENCING_BASE_URL}?sessionid=${record.zoomMeetingId}&host=true&userFullName=${props.userName}`} target="_blank">
                                Join Meeting
                                </a>
                        </Button>
                    </span>
                ),
            }}
        />
    )
}
export default TableView