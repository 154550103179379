import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import 'main.scss'

const CalanderView = (props) => {
    const events = props.calanderEvents
    const calanderPlugins = [dayGridPlugin, interactionPlugin, timeGridPlugin];

    return (
        <FullCalendar
            defaultView="dayGridMonth"
            plugins={calanderPlugins}
            eventClick={props.handleEventClick}
            dateClick={props.handleDateClick}
            events={events}
            weekends={true}
            // views={{
            //     timeGridDay: { buttonText: "Days" },
            //     timeGridWeek: { buttonText: "Weekly"}
            // }}
            header={{
                left: 'title,prev,next,today',
                center: '',
                right: 'timeGridDay,timeGridWeek,dayGridMonth'
            }}
            slotDuration='00:15:00'
        />
    )
}
export default CalanderView