import React, { Component } from 'react';
import { Form, Input, Checkbox, Select as AntSelect, Radio, TimePicker as AntTimePicker, DatePicker as AntDatePicker } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Controller, ErrorMessage } from 'react-hook-form';


const Error = (props) => {
    return (<ErrorMessage errors={props.err} name={props.name}>
        {({ message, messages }) => {
            if (messages) {
                return (
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                        <span key={type} className="text-danger warning-msg">{message}</span>
                    ))
                );
            }
            return <span className="text-danger warning-msg">{message}</span>
        }}
    </ErrorMessage>
    );
}

const TextField = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input
                            placeholder={props.placeholder}
                            style={props.style}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            prefix={props.prefix}
                            suffix={props.suffix}
                            {...props.rest}
                        />
                    }
                    rules={props.inputType === "email" && props.showEmailError ? {
                        ...props.rules, pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
                            message: 'Invalid Email'
                        }
                    } : props.rules}
                    onChange={([event]) => {
                        if (props.onChange) {
                            props.onChange(event.target.value);
                        }
                        return event.target.value;
                    }}
                    name={props.attribute}
                    control={props.control}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TextArea = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input.TextArea
                            placeholder={props.placeholder}
                            style={props.style}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            {...props.rest}
                        />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TextNumber = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input
                            placeholder={props.placeholder}
                            style={props.style}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            {...props.rest}
                        />
                    }
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    rules={props.inputType === "text-number" ? {
                        ...props.rules, validate: {
                            value: /^[0-9]*$/,
                            message: 'Invalid number!'
                        }, pattern: {
                            value: /^[0-9]*$/,
                            message: 'Invalid number!'
                        }
                    } : props.rules}
                    name={props.attribute}
                    control={props.control}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TextOnly = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input
                            placeholder={props.placeholder}
                            style={props.style}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            {...props.rest}
                        />
                    }
                    rules={props.inputType === "text-only" ? {
                        ...props.rules, validate: {
                            value: /^[a-zA-Z ]+$/,
                            message: 'Invalid text!'
                        }, pattern: {
                            value: /^[a-zA-Z ]+$/,
                            message: 'Invalid text!'
                        }
                    } : props.rules}
                    name={props.attribute}
                    control={props.control}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TextName = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input
                            placeholder={props.placeholder}
                            style={props.style}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            {...props.rest}
                        />
                    }
                    rules={props.inputType === "text-name" ? {
                        ...props.rules, validate: {
                            value: /^[a-zA-Z. _%+-]+$/,
                            message: 'Invalid text!'
                        }, pattern: {
                            value: /^[a-zA-Z. _%+-]+$/,
                            message: 'Invalid text!'
                        }
                    } : props.rules}
                    name={props.attribute}
                    control={props.control}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const Password = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <Input.Password
                            placeholder={props.placeholder}
                            disabled={props.disabled ? props.disabled : false}
                            allowClear={props.allowClear ? props.allowClear : false}
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                            {...props.rest}
                        />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    onChange={props.onChange}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const CheckBox = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <Checkbox
                            disabled={props.disabled ? props.disabled : false}
                        >
                            {props.children}
                        </Checkbox>
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    onChange={([event]) => (event.target.checked)}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const RadioGroup = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <Radio.Group {...props.rest} disabled={props.disabled ? props.disabled : false} style={{ width: "98%" }} >
                            {props.options.map((el) => (
                                <Radio key={el.value} style={el.style} value={el.value}>{el.label}</Radio>
                            ))}
                        </Radio.Group>
                    }
                    rules={props.rules}
                    name={props.attribute}
                    style={props.style}
                    control={props.control}
                    onChange={([event]) => {
                        if (props.onChange) {
                            props.onChange(event.target.value);
                        }
                        return event.target.value;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const Select = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input" label={props.label}>
                <Controller
                    as={
                        <AntSelect style={{ width: '100%' }} {...props.rest} disabled={props.disabled ? props.disabled : false} placeholder={props.placeholder} showSearch={props.showSearch} filterOption={(input, option, name) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        } notFoundContent={props.notFoundContent}>
                            {props.options.map((el) => (
                                <AntSelect.Option key={el.value} title={el.label} value={el.value}>{el.label}</AntSelect.Option>
                            ))}
                        </AntSelect>
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    onChange={([vals]) => {
                        if (props.onChange) {
                            props.onChange(vals);
                        }
                        return vals;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const DatePicker = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <AntDatePicker
                            {...props.rest}
                            style={props.style}
                            allowClear={props.allowClear ? props.allowClear : false}
                            format={props.format ? props.format : "YYYY-MM-DD"}
                            disabledDate={props.disabledDate ? props.disabledDate : disabledDate}
                            autoComplete="off"
                        />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    style={props.style}
                    onChange={([event, value]) => {
                        if (props.onChange) {
                            props.onChange(event, value);
                        }
                        return event;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const DateTimePicker = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <AntDatePicker {...props.rest} style={props.style} allowClear={props.allowClear ? props.allowClear : false} disabled={props.disabled ? props.disabled : false} format="YYYY-MM-DD HH:mm:ss" autoComplete="off" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    style={props.style}
                    onChange={([event, value]) => {
                        if (props.onChange) {
                            props.onChange(event, value);
                        }
                        return event;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days');
}

const DateRangePicker = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <AntDatePicker.RangePicker
                            disabledDate={disabledDate}
                            {...props.rest}
                            style={props.style}
                            allowClear={props.allowClear ? props.allowClear : false}
                            disabled={props.disabled ? props.disabled : false}
                            autoComplete="off"
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment(), moment().endOf('week')],
                                'This Month': [moment(), moment().endOf('month')],
                            }}
                        />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    style={props.style}
                    onChange={([event, value]) => {
                        if (props.onChange) {
                            props.onChange(event, value);
                        }
                        return event;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TimePicker = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <AntTimePicker {...props.rest} autoComplete="off" style={props.style} minuteStep={props.minuteStep} allowClear={props.allowClear ? props.allowClear : false} disabled={props.disabled ? props.disabled : false} use12Hours format="h:mm A" />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    onChange={([event, value]) => {
                        if (props.onChange) {
                            props.onChange(event, value);
                        }
                        return event;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

const TimeRangePicker = (props) => {
    return (
        <div className="position-relative ant-form-vertical">
            <Form.Item className="from-field-input text-left" label={props.label}>
                <Controller
                    as={
                        <AntTimePicker.RangePicker
                            {...props.rest} style={props.style}
                            allowClear={props.allowClear ? props.allowClear : false}
                            disabled={props.disabled ? props.disabled : false}
                            format={props.format}
                            style={props.style}
                            use12Hours
                        />
                    }
                    rules={props.rules}
                    name={props.attribute}
                    control={props.control}
                    style={props.style}
                    onChange={([event, value]) => {
                        if (props.onChange) {
                            props.onChange(event, value);
                        }
                        return event;
                    }}
                />
            </Form.Item>
            <Error err={props.errors} name={props.attribute} />
        </div>
    );
};

class FormFieldInput extends Component {
    render() {
        switch (this.props.inputType) {
            case 'checkbox':
                return <CheckBox {...this.props} />;
            case 'radio-group':
                return <RadioGroup {...this.props} />;
            case 'password':
                return <Password {...this.props} />;
            case 'select':
                return <Select {...this.props} />;
            case 'textarea':
                return <TextArea {...this.props} />;
            case 'text-number':
                return <TextNumber {...this.props} />;
            case 'text-only':
                return <TextOnly {...this.props} />;
            case 'text-name':
                return <TextName {...this.props} />;
            case 'date-picker':
                return <DatePicker {...this.props} />;
            case 'date-time-picker':
                return <DateTimePicker {...this.props} />;
            case 'date-range-picker':
                return <DateRangePicker {...this.props} />;
            case 'time-picker':
                return <TimePicker {...this.props} />;
            case 'time-range-picker':
                return <TimeRangePicker {...this.props} />;
            default:
                return <TextField {...this.props} />;
        }
    }
}

FormFieldInput.defaultProps = {
    inputType: 'text',
    inputClass: 'form-control',
    required: false,
    disabled: false,
    showLabel: true,
    showErrors: false,
    showSearch: false,
    items: [],
    label: '',
    errors: null,
    rest: {},
    options: [],
    rules: {},
    format: 'hh:mm:ss a',
    showEmailError: true
};

FormFieldInput.propTypes = {
    inputType: PropTypes.string,
    handleChange: PropTypes.func,
    inputClass: PropTypes.string,
    attribute: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
    showErrors: PropTypes.bool,
    showSearch: PropTypes.bool,
    falseCheckboxText: PropTypes.string,
    trueCheckboxText: PropTypes.string,
    validation: PropTypes.string,
    items: PropTypes.array,
    prefix: PropTypes.object,
    suffix: PropTypes.object,
    maxLength: PropTypes.number,
    style: PropTypes.any,
    errors: PropTypes.object,
    rest: PropTypes.any,
    options: PropTypes.array,
    rules: PropTypes.object,
    control: PropTypes.any,
    format: PropTypes.string,
    showEmailError: PropTypes.bool
};

export default FormFieldInput;
