import React from 'react';
import { useForm } from 'react-hook-form';
import FormLayout from 'layouts/form-layout';
import LinkInline from 'elements/link-inline';
import FormFieldInput from 'elements/form-field-input';
import Button from 'elements/button';

export default (props) => {

    const defaultValues = {
        password: '',
    };

    const { handleSubmit, control, errors, getValues } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        try {
            const token = props.match.params.token;

            if (!token) throw new Error('Token not found');

            await window.$http.rawPut('reset-password', {
                newPassword: data.password,
                token,
            });

            window.$utility.showSucessMessage("Password successfully updated");

            props.history.push('/login');
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };

    return (
        <FormLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <span className="login100-form-title">Reset Password</span>
                <p>Enter your new password</p>
                <div className="form-group has-feedback">
                    <FormFieldInput
                        attribute="password"
                        inputType="password"
                        placeholder="Enter your password"
                        control={control}
                        errors={errors}
                        rules={{
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Invalid Password (Length should be > 6)'
                            }
                        }}
                        rest={{ maxLength: 30 }}
                    />
                </div>
                <div className="form-group has-feedback">
                    <FormFieldInput
                        attribute="confirmPassword"
                        inputType="password"
                        placeholder="Confirm your password"
                        control={control}
                        errors={errors}
                        rules={{
                            required: 'Password is required',
                            validate: value => {
                                const vals = getValues();
                                if (vals && vals.password && value === vals.password) {
                                    return null;
                                }
                                return 'Password Inconsistent';
                            },
                            minLength: {
                                value: 6,
                                message: 'Invalid Password (Length should be > 6)'
                            }
                        }}
                        rest={{ maxLength: 30 }}
                    />
                </div>
                <div>
                    <div className="container-login100-form-btn">
                        <Button type="danger" size="large" onClick={handleSubmit(onSubmit)}>Reset Password</Button>
                    </div>
                    <div>
                        <p className="text-center  mt-2">
                            <LinkInline label="Back to login" route="/login" />
                        </p>
                    </div>
                </div>
            </form>
        </FormLayout>
    );
}
