import React from 'react';
import { connect } from 'react-redux';
import { DownOutlined, UserOutlined, UnlockOutlined, LogoutOutlined } from '@ant-design/icons';
import { logout } from 'store/auth/action';
import { Dropdown, Menu, Avatar } from 'antd';
import { Link } from 'react-router-dom';


const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        }
    }
}

const Header = (props) => {
    const name = props && props.user && props.user.name ? props.user.name : 'Admin';
    const signOut = (e) => {
        e.preventDefault();
        props.logout();
        props.history.push('/login');
    };

    const menu =
        (<Menu>
            {/* <Menu.Item key="0">Hi {name}</Menu.Item>
            <Menu.Item key="1"><Link to='/admin/profile' className="inline-block"><UserOutlined style={{ color: '#08c' }} /><span className="ml-1">Profile</span></Link></Menu.Item> */}
            <Menu.Item key="2"><Link to='/admin/change-password' className="inline-block"><UnlockOutlined style={{ color: '#002FA7' }} /><span className="ml-1">Change Password</span></Link></Menu.Item>
            <Menu.Item key="3" ><Link to='/login' className="inline-block" onClick={signOut}><LogoutOutlined style={{ color: '#002FA7' }} /><span className="ml-1">Logout</span></Link></Menu.Item>
        </Menu>);

    return (
        <span className="pull-right">
            <Dropdown overlay={menu} trigger={['click']} onClick={e => e.preventDefault()}>
                <a href="/" className="ant-dropdown-link anchor-color fw-500" onClick={e => e.preventDefault()}>
                    <Avatar style={{ color: 'rgb(0, 17, 56)', backgroundColor: 'rgba(0, 17, 56, .33)' }}>{name[0]}</Avatar>
                    <span className="ml-1">{name}</span> <DownOutlined />
                </a>
            </Dropdown>
        </span>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
