import React, { useState, useEffect } from 'react';
import FormLayout from 'layouts/form-layout/index';
import Footer from 'layouts/form-layout/footer';
import { CheckOutlined } from "@ant-design/icons";

const PaymentSuccess = (props) => {

    return (
        <div className="payment">
            <FormLayout>
                <div className="container-fluid">
                    <span className="login100-form-title pb-3 pt-1">Payment Success</span>
                    <div className="row">
                        <div className="col-sm-12 bg-white">
                            <div className="cu-tion py-3 d-flex">
                                <div className="icon">
                                    <CheckOutlined style={{ color: '#58c5c4', fontSize: '40px' }} />
                                </div>
                                <div className="data ml-3">
                                    <span className="heading d-block">Payment Successfull</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer type="register" />
            </FormLayout>
        </div>
    );
}


export default PaymentSuccess;
