import React, { Component } from 'react';
import navigationRoutes from './nav';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { StockOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
const { SubMenu } = Menu;

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

class SideBar extends Component {

    constructor(props) {
        super(props)
        this.sideNavigationRoutes = navigationRoutes

        const isAvailable = this.sideNavigationRoutes.items.map(a => a.title)
        if (this.props.user && this.props.user.isPaymentAllowed && !isAvailable.includes('Ledger')) {
            this.sideNavigationRoutes.items.push({
                title: 'Ledger',
                url: '/clinic-admin/ledger',
                icon: <StockOutlined />,
            })
        }
    }

    rootSubmenuKeys = ['sub1', 'sub2'];
    state = {
        openKeys: [''],
    };
    onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    getMenuItems(data) {
        if (data && data.length) {
            return data.map(el => {
                if (el.children && el.children.length) {
                    return (
                        <SubMenu
                            key={el.title}
                            title={
                                // el.url ?
                                //     <Link to={el.url} >
                                //         {el.icon ? <Icon type={el.icon} /> : ''}
                                //         <span>
                                //             {el.title}
                                //         </span>
                                //     </Link>
                                //     :
                                <span>
                                    {el.icon || ''}
                                    <span>
                                        {el.title}
                                    </span>
                                </span>
                            }
                        >
                            {this.getMenuItems(el.children)}
                        </SubMenu >
                    );
                }
                return (
                    <Menu.Item key={el.title}>
                        <Link to={el.url}>
                            {el.icon || ''}
                            <span>{el.title}</span>
                        </Link>
                    </Menu.Item>
                )
            });
        }
        return null;
    }

    render() {
        return (
            <div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} openKeys={this.state.openKeys} onOpenChange={this.onOpenChange.bind(this)}>
                    {this.getMenuItems(this.sideNavigationRoutes.items)}
                </Menu>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
