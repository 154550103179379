import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class ButtonComponent extends Component {
    render() {
        return (
            <Button
                key={this.props.key}
                className={this.props.inputClass}
                type={this.props.type}
                shape={this.props.shape}
                icon={this.props.icon}
                size={this.props.size}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            > {this.props.children}
            </Button>
        );
    }
}

ButtonComponent.defaultProps = {
    type: 'default',
    shape: 'round',
    size: 'large',
    // icon: PropTypes.string,
    disabled: false,
    loading: false,
    key: 'submit'
};

ButtonComponent.propTypes = {
    key: PropTypes.string,
    type: PropTypes.string, // default, primary, danger, dashed, link
    shape: PropTypes.string, // circle, round , omitted
    size: PropTypes.string, // small, large , omitted
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    inputClass: PropTypes.string,
};

export default ButtonComponent;
