import {
    Dashboard,
    ChangePassword,
    Profile,
    Patient,
    Doctor,
    ManageAppointment,
    Ledger,
    LedgerDetail
} from 'pages/clinic-admin/component';

const url = '/clinic-admin';

export default [
    {
        url: `${url}/dashboard`,
        name: 'Dashboard',
        component: Dashboard
    },
    {
        url: `${url}/patient`,
        name: 'Patient Management',
        component: Patient
    },
    {
        url: `${url}/physician`,
        name: 'Physician Management',
        component: Doctor
    },
    {
        url: `${url}/change-password`,
        name: 'Change Password',
        component: ChangePassword
    },
    {
        url: `${url}/profile`,
        name: 'Profile',
        component: Profile
    },
    {
        url: `${url}/manage-appointment`,
        name: 'Manage Appointment',
        component: ManageAppointment
    },
    {
        url: `${url}/ledger`,
        name: 'Ledger Management',
        component: Ledger
    },
    {
        url: `${url}/ledger-detail/:doctorId`,
        name: 'Ledger Details',
        component: LedgerDetail
    },
];
