import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery, useMutation } from 'services/graphQL';
import { Button, Tooltip, Popconfirm } from 'antd';
import { EyeOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ManagePatient from './manage-patient';
import { debounce } from "lodash";
import moment from 'moment';
import ManageAppointment from 'elements/manage-appointment';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const Patient = (props) => {

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]],
        sequelizeJson: {
            where: {
                'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
            }
        }
    });
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalData, setModalData] = useState({});
    const [skipQuery, setSkipQuery] = useState(true);

    const [appointmentModalVisibility, setAppointmentModalVisibility] = useState(false);
    const [appointmentModalData, setAppointmentModalData] = useState({});

    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountPatients ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountPatients (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        gender
                        dob
                        user {
                            name
                            email 
                            phone
                            id
                            isEmailVerified
                            isPhoneVerified
                        }
                    }
                    count
                }
            }
        `,
        {
            skip: skipQuery,
            variables
        }
    );

    const { data: users, refetch: refetchUsers } = useQuery(gql`
        query {
            patients {
                id
                createdBy
                user { id name }
            }
            doctors {
                id
                createdBy
                user { id name }
            }
            appointments {
                id
                startDate
                startTime
                createdBy
                doctorId
                patientId
            }
            mstAppointmentTypes {
                id
                name
            }
        }
    `);

    const [createPatient, { data: createdPatient }] = useMutation(
        gql`
          mutation createPatient($input: PatientCreateInput!) {
            createPatient(input: $input) {
                id
            }
        }
      `
    );

    const [createAppointment, { data: createdData }] = useMutation(gql`
        mutation createAppointment($inputAppointment: AppointmentCreateInput!) {
            createAppointment(input: $inputAppointment) {
                id
            }
        }
    `);

    const [updateUser, { data: updatedUser }] = useMutation(
        gql`
          mutation updateUser($userId: ID!, $input: UserUpdateInput!) {
            updateUser(id: $userId, input: $input) {
                id
            }
        }
      `
    );

    const [updatePatient, { data: updatedPatient }] = useMutation(
        gql`
          mutation updatePatient($userId: ID!, $input: PatientCreateInput!) {
            updatePatient(id: $userId, input: $input) {
                id
            }
        }
      `
    );

    const [delPatient, { data: deletedData }] = useMutation(
        gql`
          mutation deletePatient($id: ID!) {
            deletePatient(id: $id) {
                id
            }
        }
      `
    );

    if (createdPatient || updatedUser || deletedData || updatedPatient) {
        refetch();
        refetchUsers();
    }

    const createOrUpdate = async (event) => {
        try {
            if (!event.id) {
                event.password = '123456';
                event.createdBy = props.user && props.user.clinic ? props.user.clinic.id : null;

                const result = await window.$http.postWithoutHeaders('register/patient', event);

                if (!result || !result.user || !result.user.id)
                    return window.$utility.showErrorMessage('User Id not found!');

                const data = {
                    userId: result.user.id,
                    gender: event.gender,
                    dob: event.dob,
                    createdBy: props.user && props.user.clinic ? props.user.clinic.id : null
                };

                debounceSearch(result.user.phone)

                createPatient({
                    variables: {
                        input: data
                    }
                })
            } else {
                const patient = {
                    gender: event.gender,
                    dob: event.dob,
                };
                const user = {
                    name: event.name,
                    email: event.email,
                    phone: event.phone,
                };

                event.id = parseInt(event.id);
                event.userId = parseInt(event.userId);

                updatePatient({
                    variables: {
                        userId: event.id,
                        input: patient
                    }
                })
                if (event.userId) {
                    updateUser({
                        variables: {
                            userId: event.userId,
                            input: user
                        }
                    })
                }
            }
        } catch (err) {
            console.log(err)
            window.$utility.showErrorMessage(err.message);
        }
    }

    const deletePatient = (record) => {
        if (record && record.id) {
            delPatient({
                variables: {
                    id: record.id
                }
            });
        }
    };

    const addPatientAppointment = (record, mode) => {
        setAppointmentModalData({ mode, patientId: record.id, isOnline: 'online' });
        setAppointmentModalVisibility(true);
    }

    const createAndCheckUniqUrl = async () => {
        try {
            let shortUrl = window.$utility.createShortHash(9);

            const meetings = await window.$http.get('appointment', {
                where: {
                    shortMeetingId: shortUrl
                }
            });

            if (meetings.length) {
                return createAndCheckUniqUrl()
            } else {
                return shortUrl
            }

        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const submitPatientAppointment = async (event) => {
        const data = {
            doctorId: event.doctorId && parseInt(event.doctorId),
            patientId: event.patientId && parseInt(event.patientId),
            startDate: event.date ? moment(event.date).format('YYYY-MM-DD') : null,
            // endDate: moment(event.date[1]).format('YYYY-MM-DD'),
            startTime: moment(event.time).format('YYYY-MM-DD hh:mm:ss a'),
            appointmentTypeId: event.appointmentTypeId && parseInt(event.appointmentTypeId),
            // endTime: moment(event.time[1]).format('YYYY-MM-DD hh:mm:ss a'),
            // meetingDate: event.meetingDate,
            zoomMeetingId: event.zoomMeetingId && event.zoomMeetingId.toString(),
            createdBy: props.user && props.user.clinic ? props.user.clinic.id : null,
            isPaymentRequired: event.isPaymentRequired,
            isOnline: event.isOnline
        }

        data.status = event.isPaymentRequired ? 'scheduled' : 'confirmed'

        const shortMeetingId = await createAndCheckUniqUrl();
        if (!shortMeetingId) {
            return window.$utility.showErrorMessage('Unable to create short meeting url! ')
        }

        data.status = event.isPaymentRequired ? 'scheduled' : 'confirmed';

        const startDate = moment(event.date).format('YYYY-MM-DD');
        const startTime = moment(event.time).format('HH:mm:ss');

        const formatedDate = moment(startDate + " " + startTime, ['YYYY-MM-DD HH:mm:ss']);

        data.meetingDate = formatedDate;
        data.shortMeetingId = shortMeetingId;
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRG9jdG9yLU1lZXRpbmciLCJ0eXBlIjoiQ29uc3RhbnQgVG9rZW4iLCJ1c2VySWQiOjF9.uQCCosoyhQ6fstarcn_aSIL_GGStVC0s8teRrtg7pfk'
        const response = await fetch(`${process.env.REACT_APP_VIDEO_CONFERENCING_API_URL}/create-meeting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify({ meetingAt: formatedDate })
        });
        const result = await response.json();

        createAppointment({ variables: { inputAppointment: { ...data, ...{ zoomMeetingId: result.object.meetingId } } } })
    }

    const manageContact = (record) => {
        const mode = record ? 'edit' : 'add';
        const data = {
            ...record,
            mode
        };

        setModalData(data);
        setModalVisibility(true);
    };

    const debounceSearch = debounce((value) => {
        if (!value) return
        setSkipQuery(false)
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        // {
                        //     'gender': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        // {
                        //     'age': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        {
                            '$User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.email$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.phone$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ],
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                },
                include: [
                    {
                        model: 'User'
                    }
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {
        setSkipQuery(false)

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        });
    };

    const getPatientList = () => {
        setSkipQuery(false)
        setVariables({
            offset: 0,
            limit: 10,
            order: [["id", "DESC"]],
            sequelizeJson: {
                where: {
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        })
        refetch()
    }

    const onClose = () => {
        setModalVisibility(false);
        setModalData({})

        setAppointmentModalVisibility(false);
        setAppointmentModalData({});
    };
    const capatilizeText = (text) => {
        const name = text ? text.charAt(0).toUpperCase() + text.slice(1) : 'NA'
        return name
    };

    const sendVerificationEmail = async (data) => {
        try {
            const userId = data && data.user ? +data.user.id : null;

            if (!userId)
                return window.$utility.showErrorMessage('User not found!')

            const result = await window.$http.patch(`user/send-verification/${userId}`)
            if (result) {
                window.$utility.showSucessMessage('Verification email sent!')
            }
        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            align: 'left',
            sorter: false,
            render: (text) => (text && text.name ? text.name : 'NA'),
            width: 160,
            className: 'space-nowrap',
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            align: 'left',
            sorter: false,
            render: (text) => (text && text.email ? text.email : 'NA'),
            width: 200,
            // className:'space-nowrap',
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            key: 'user',
            align: 'left',
            sorter: false,
            render: (text) => (text && text.phone ? text.phone : 'NA'),
            width: 120,
            className: 'space-nowrap',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            align: 'left',
            sorter: true,
            width: 100,
            render: (text) => (capatilizeText(text)),
            className: 'space-nowrap',
            showSorterTooltip: false
        },
        {
            title: 'Email verified',
            dataIndex: 'user',
            key: 'user',
            align: 'left',
            sorter: false,
            width: 100,
            render: (text) => <span className={text.isEmailVerified ? "text-success" : "text-danger"}>{text && text.isEmailVerified ? 'Yes' : 'No'}</span>,
            className: 'space-nowrap',
        },
        {
            title: 'Phone verified',
            dataIndex: 'user',
            key: 'user',
            align: 'left',
            sorter: false,
            width: 100,
            render: (text) => <span className={text.isPhoneVerified ? "text-success" : "text-danger"}>{text && text.isPhoneVerified ? 'Yes' : 'No'}</span>,
            className: 'space-nowrap',
        },
        // render: (text) => (text && text.isEmailVerified ? 'Yes' : 'No'),
        // {
        //     title: 'DOB',
        //     dataIndex: 'dob',
        //     key: 'dob',
        //     align: 'left',
        //     sorter: true,
        //     width: 80,
        //     render: (text) => (text ? moment(text).format('DD MMM, YYYY') : 'NA'),
        //     className: 'space-nowrap',
        //     showSorterTooltip: false
        // }
    ];

    return (
        <Fragment>
            <DataTable
                title="Patient"
                data={data && data.findAndCountPatients && data.findAndCountPatients.rows ? data.findAndCountPatients.rows : null}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={data && data.findAndCountPatients && data.findAndCountPatients.count ? data.findAndCountPatients.count : 0}
                loading={loading}
                onChange={handleChange}
                notShowSearchAndAdd={false}
                createOrUpdateAction={manageContact}
                getPatientList={getPatientList}
                // deleteAction={deletePatient}
                customAction={{
                    title: 'Action',
                    key: 'action',
                    align: 'center',
                    width: 120,
                    render: (text, record) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title="Add Patient Appointment">
                                <Button type="link" shape="circle" icon={<PlusCircleOutlined />} onClick={() => addPatientAppointment(record, 'edit')} />
                            </Tooltip>
                            <Tooltip title="Edit Patient">
                                <Button type="link" shape="circle" icon={<EditOutlined />} onClick={() => manageContact(record, 'edit')} />
                            </Tooltip>
                            <Tooltip title="Send verification email">
                                <Button type="link" shape="circle" icon={<CheckCircleOutlined />} onClick={() => sendVerificationEmail(record)} />
                            </Tooltip>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deletePatient(record)}>
                                <Button type="link-danger" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </span>
                    ),
                }}
            />
            <ManagePatient
                visible={modalVisibility}
                onClose={onClose}
                data={modalData}
                onSubmit={createOrUpdate}
            />
            <ManageAppointment
                {...props}
                visible={appointmentModalVisibility}
                onClose={onClose}
                data={appointmentModalData}
                onSubmit={submitPatientAppointment}
                patients={users && users.patients}
                doctors={users && users.doctors}
                mstAppointmentTypes={users && users.mstAppointmentTypes}
                appointments={users && users.appointments}
                disablePatient={true}
                userId={props.user && props.user.clinic ? props.user.clinic.id : null}
                isPaymentAllowed={props.user && props.user.isPaymentAllowed}
            />
        </Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
