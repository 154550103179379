import React, { useState, useEffect } from 'react';
import HeaderLayout from './header';
import Sidebar from './sidebar';
import { HomeOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb } from 'antd';
import logoLg from 'assets/images/doctor-icon.png';
import logoSm from 'assets/images/doctor-icon.png';
import { Link, withRouter } from 'react-router-dom';
import AdminRoutes from 'app/routes/nav/admin-routes';

const { Header, Sider, Content, Footer } = Layout;

let breadcrumbNameMap = {
    '/admin': 'Admin',
};

AdminRoutes.forEach(element => {
    const url = element.url.split('/:')[0];
    breadcrumbNameMap = { ...breadcrumbNameMap, [url]: element.name };
});
const AdminLayout = withRouter(props => {

    // Set Menu Properties
    const [collapsed, setCollapsed] = useState(false);
    const [siderProps, setSiderProps] = useState({ collapsedWidth: 80 });
    const [headerClass, setHeaderClass] = useState([])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setCollapsed(true);
            setSiderProps({ breakpoint: "lg", collapsedWidth: 0, defaultCollapsed: true });
        }
    }, []);

    let logoImg = <img src={logoLg} alt="Logo" />;
    if (collapsed) {
        logoImg = <img src={logoSm} alt="Logo" />;
    }

    const addClasses = () => {
        if(!collapsed) {
            setHeaderClass(['header-content'])
        } else {
            setHeaderClass(['sb-open'])
        }
    }
    // Set Menu Properties

    // Set Breadcrum Properties
    const { location } = props;
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{breadcrumbNameMap[url]}</Link>
            </Breadcrumb.Item>
        );
    });
    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <Link to="/"><HomeOutlined style={{ color: '#1890ff' }} /></Link>
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
    // Set Breadcrum Properties

    return (
        <div className="admin-layout">
            <Layout style={{ minHeight: '100vh' }}>
                <Sider className="cu-sb" trigger={null} collapsible {...siderProps} collapsed={collapsed} >
                    <div className="logo doctor-logo">
                        {logoImg}
                    </div>
                    <Sidebar />
                </Sider>
                <Layout>
                    <Header style={{ background: '#fff' }} className={headerClass}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => {setCollapsed(!collapsed); addClasses()},
                        })}
                        <HeaderLayout history={props.history} />
                    </Header>
                    <Content className={headerClass} style={{ borderRadius: '4px', margin: '16px 16px 0 16px', padding: 24, background: '#fff', minHeight: 280, }}>
                        <Breadcrumb style={{fontWeight: 800, marginBottom: 8}}>{breadcrumbItems}</Breadcrumb>
                        {/* <Breadcrumb>
                            
                            <Breadcrumb.Item href="">
                                <span>Parent</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Child</Breadcrumb.Item>
                        </Breadcrumb> */}
                        <div className="admin-dashboard">
                            {props.children}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Copyright © 2020 - All rights reserved | RxOnCall </Footer>
                </Layout>
            </Layout>
        </div>
    );
});

export default AdminLayout;
