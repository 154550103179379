import React, { useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from 'services/graphQL';

export default function ShortMeet(props) {
    const VIDEO_CONFERENCING_URL = 'https://app.rxoncall.com/conference/demos/dashboard/terms-and-condition.html';
    const [variables, setVariables] = useState({
        sequelizeJson: {
            'shortMeetingId': props.match.params.meetingId
        }
    });

    const { loading, data, refetch } = useQuery(gql`
        query appointments($sequelizeJson: SequelizeJSON){
            appointments(where: $sequelizeJson) {
                zoomMeetingId
                patient { user { name } }
            }
        }
    `, { variables }
    );

    if (data && data.appointments && data.appointments.length) {
        const meet = data.appointments[0];

        const meetingId = meet.zoomMeetingId;
        const userName = meet.patient && meet.patient.user && meet.patient.user.name;

        window.location.href = `${VIDEO_CONFERENCING_URL}?sessionid=${meetingId}&userFullName=${userName}`
    }

    return (
        <div className="payment">
            {loading ? <div>Loading...</div> : <div> Meeting not found !</div>}
        </div>
    )
}
