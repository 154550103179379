import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button } from 'antd';
import FormFieldInput from 'elements/form-field-input';
import { gql, useMutation } from 'services/graphQL';
import { updateUser } from 'store/auth/action';


const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {
        update: (payload) => {
            dispatch(updateUser(payload))
        }
    }
}

const Profile = (props) => {

    const [updateUser, { data: mutationData }] = useMutation(
        gql`
            mutation updateUser($id: ID!, $userCreateInput: UserUpdateInput!) {
                updateUser(id: $id, input: $userCreateInput) {
                    id
                }
            }
        `);

    let defaultValues;
    if (props.user) {
        defaultValues = props.user;
    }

    if (mutationData) {
        window.$utility.showSucessMessage('Details are updated');
    }

    const { handleSubmit, register, errors, control } = useForm({
        defaultValues
    });

    useEffect(() => {
        register({ name: "id" });
    }, [register]);

    const onSubmit = async (data) => {
        props.update({ ...props.user, ...data });
        const { id, ...vals } = data;
        updateUser({
            variables: {
                userCreateInput: {
                    ...vals
                },
                id
            }
        });
    };

    return (
        <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 mx-auto">
                            <div className="row">
                                <div className="col-sm-12 text-left ">
                                    <FormFieldInput
                                        inputType="text"
                                        placeholder="Enter Name"
                                        attribute="name"
                                        control={control}
                                        errors={errors}
                                        label="Name"
                                        rules={{
                                            required: 'Name is required',
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 text-left">
                                    <FormFieldInput
                                        inputType="text"
                                        placeholder="Enter Phone number"
                                        attribute="phone"
                                        control={control}
                                        errors={errors}
                                        label="Phone number"
                                        rules={{
                                            required: 'Phone number is required',
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 text-left ">
                                    <FormFieldInput
                                        inputType="text"
                                        placeholder="Enter Email"
                                        attribute="email"
                                        control={control}
                                        errors={errors}
                                        disabled="true"
                                        label="Email"
                                        rules={{
                                            required: 'Email is required',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <Button key="submit" type="primary">Submit</Button>
                </div>
        </form>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
