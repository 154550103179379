import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthGuard from './auth-guard';
import NavigateLoggedInUser from './navigate-logged-in-user';
// Common Pages
import Login from 'pages/common/login';
import Register from 'pages/common/register';
import ForgotPassword from 'pages/common/forgot-password';
import ResetPassword from 'pages/common/reset-password';
import VerifyEmail from 'pages/common/verify-email';
import NotFound from "pages/common/not-found";

// Admin Pages
import AdminRoutes from './nav/admin-routes';
import DoctorRoutes from './nav/doctor-routes';
import ClinicRoutes from './nav/clinic-admin-routes';
import Clinic from 'pages/clinic-admin';
import Admin from 'pages/admin';
import Doctor from 'pages/doctor';
import ShortMeet from "pages/short-meet";
import PrivacyPolicy from "pages/privacy-policy";

// Payment Pages
import Payment from 'pages/payment'
import PaymentSuccess from 'pages/payment/payment-success'

export default () => {

    const adminRoutesRedirection = ['/admin/physician-list', '/admin/appointment-list', '/admin/patient-list', '/superAdmin/', '/admin', '/admin/']
    const clinicRoutesRedirection = ['/practiseAdmin/', '/clinic-admin', '/superadmin/manage-appointment', '/practiseAdmin/manage-appointment']
    const doctorRoutesRedirection = ['/physician/', '/doctor/', '/doctor/dashboard']

    return (
        <Switch>
            {/* Public Routes */}
            <NavigateLoggedInUser path="/login"><Route path="/login" component={Login} /></NavigateLoggedInUser>
            <Route path="/clinic/register" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/verify-email/:token" component={VerifyEmail} />

            <Route path="/appointment/payment/:appointmentId" component={Payment} />
            <Route path="/appointment/payment-success" component={PaymentSuccess} />
            <Route path="/short-meet/:meetingId" component={ShortMeet} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />

            <Route exact path="/">
                <Redirect to="/login" />
            </Route>
            {/* Public Routes */}

            {/* Admin routes redirection */}
            {
                adminRoutesRedirection.map(a => (
                    <Route exact path={a} key={a}>
                        {/* <Redirect to="/admin/dashboard" /> */}
                        <Redirect to="/admin/clinic-list" />
                    </Route>
                ))
            }

            <Route exact path='/admin/ledger-detail'>
                <Redirect to="/admin/ledger" />
            </Route>

            <Route exact path='/clinic-admin/ledger-detail'>
                <Redirect to="/clinic-admin/ledger" />
            </Route>

            {/* Clinic admin url redirection */}
            {
                clinicRoutesRedirection.map(a => (
                    <Route exact path={a} key={a}>
                        {/* <Redirect to="/admin/dashboard" /> */}
                        <Redirect to="/clinic-admin/manage-appointment" />
                    </Route>
                ))
            }

            {/* Physician admin url redirection */}
            {
                doctorRoutesRedirection.map(a => (
                    <Route exact path={a} key={a}>
                        {/* <Redirect to="/admin/dashboard" /> */}
                        <Redirect to="/physician/dashboard" />
                    </Route>
                ))
            }

            <AuthGuard role={['admin', 'superAdmin']} path="/admin">
                <Route
                    path="/admin"
                    render={(props) => {
                        return (
                            <Admin {...props} >
                                {AdminRoutes.map(el => (<Route path={el.url} key={el.url} component={el.component} />))}
                            </Admin>
                        )
                    }}
                />
            </AuthGuard>

            <AuthGuard role={['practiseAdmin']} path="/clinic-admin">
                <Route
                    path="/clinic-admin"
                    render={(props) => {
                        return (
                            <Clinic {...props} >
                                {ClinicRoutes.map(el => (<Route path={el.url} key={el.url} component={el.component} />))}
                            </Clinic>
                        )
                    }}
                />
            </AuthGuard>

            <AuthGuard role={['doctor']} path="/physician">
                <Route
                    path="/physician"
                    render={(props) => {
                        return (
                            <Doctor {...props} >
                                {DoctorRoutes.map(el => (<Route path={el.url} key={el.url} component={el.component} />))}
                            </Doctor>
                        )
                    }}
                />
            </AuthGuard>
            {/* Admin Dashboard */}
            {/* Not Found Routes */}
            <Route exact path="/404" component={NotFound} />
            <Route exact path="*">
                <Redirect to="/404" />
            </Route>
            {/* Not Found Routes */}

        </Switch>
    );
}
