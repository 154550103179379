import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
    return (
        <div className="page_404">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 mx-auto text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center ">COMING SOON</h1>
                            </div>
                            <div className="contant_box_404">
                                <h5>Looks like you are lost? No worries mate.</h5>
                                <Link to='/' className="link_404 h6 font-weight-bold">Go to our Home Page</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
