import React, { useState, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery } from 'services/graphQL';
import { debounce } from "lodash";

const Doctor = (props) => {
    const userId = props.match.params.userId;

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]],
        sequelizeJson: {
            where: {
                'createdBy': userId
            }
        }
    });

    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountDoctors ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountDoctors (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        specialityId
                        picture
                        user {
                            name
                            email
                            phone
                            isAccountLocked
                            id
                            tempPassword
                        }
                        mstSpeciality { id name }
                    }
                    count
                }
            }
        `,
        {
            variables
        }
    );

    const debounceSearch =  debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        {
                            'speciality': {
                                $iLike: `%${value}%`
                            }
                        },
                        {
                            '$User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.email$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.phone$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ],
                    'createdBy': userId
                },
                include: [
                    {
                        model: 'User'
                    },
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'descend' ? 'DESC' : 'ASC'])),
            sequelizeJson: {
                where: {
                    'createdBy': userId
                }
            }
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'name',
            align: 'left',
            sorter: false,
            className:'space-nowrap',
            render: (text) => (text && text.name ? text.name : 'NA'),
            width:180,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'email',
            align: 'left',
            sorter: false,
            // className:'space-nowrap',
            render: (text) => (text && text.email ? text.email : 'NA'),
            width:250,
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            key: 'phone',
            align: 'left',
            sorter: false,
            className:'space-nowrap',
            width:130,
            render: (text) => (text && text.phone ? text.phone : 'NA')
        },
        {
            title: 'Speciality',
            dataIndex: 'mstSpeciality',
            key: 'name',
            align: 'left',
            className:'space-nowrap',
            sorter: true,
            width:120,
            render: (text) => (text ? text.name : 'NA'),
            showSorterTooltip: false
        },
    ];

    return (
        <Fragment>
            {/* <Button type="primary" size="large" icon={<PlusCircleOutlined />} title="Add" onClick={() => { manageDoctor({}, 'add') }} /> */}
            <DataTable
                title="Doctor"
                data={data && data.findAndCountDoctors && data.findAndCountDoctors.rows ? data.findAndCountDoctors.rows : null}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={data && data.findAndCountDoctors && data.findAndCountDoctors.count ? data.findAndCountDoctors.count : 0}
                loading={loading}
                onChange={handleChange}
                notShowSearchAndAdd={true}
                customAction={'null'}
            />
        </Fragment>
    )
};

export default Doctor;
