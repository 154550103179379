import React from 'react';
import { useForm } from 'react-hook-form';
import FormLayout from 'layouts/form-layout';
import LinkInline from 'elements/link-inline';
import FormFieldInput from 'elements/form-field-input';
import { MailOutlined } from '@ant-design/icons';
import Button from 'elements/button';

export default (props) => {

    const defaultValues = {
        email: '',
    };

    const { handleSubmit, control, errors } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        try {
            const result = await window.$http.postWithoutHeaders('forgot-password', data);
            window.$utility.showSucessMessage(result.message);
            props.history.push('/login');
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };

    return (
        <FormLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <span className="login100-form-title">Forgot Password</span>
                <p className="text-center">Enter your Email and instructions will be sent to you! </p>
                <div className="form-group has-feedback">
                    <FormFieldInput
                        attribute="email"
                        inputType="email"
                        control={control}
                        errors={errors}
                        placeholder="Enter your email"
                        rules={{
                            required: 'Email is required'
                        }}
                        rest={{ maxLength: 50 }}
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                    />
                </div>
                <div>
                    <div className="container-login100-form-btn">
                        <Button type="danger" size="large" onClick={handleSubmit(onSubmit)}>Reset Password</Button>
                    </div>
                    <div>
                        <p className="text-center  mt-2">
                            <LinkInline label="Back to login" route="/login" />
                        </p>
                    </div>
                </div>
            </form>
        </FormLayout>
    );
}
