import React, { Fragment } from 'react';
import DataTable from 'elements/data-table';
import { Button, Tooltip} from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const Ledger = (props) => {

    const ledgerData = props.data;
    const columns = props.columns;

    const goToClinicLedger = (data) => {
        const doctorId = data.doctorId ? data.doctorId : null;
        if (doctorId) {
            props.history.push(`/clinic-admin/ledger-detail/${doctorId}`);
        }
    }

    return (
        <Fragment>
            <DataTable
                title="Ledger"
                data={ledgerData && ledgerData.rows ? ledgerData.rows : []}
                columns={columns}
                debounceSearch={props.debounceSearch}
                totalRecords={ledgerData && ledgerData.count ? ledgerData.count : 0}
                loading={props.loading}
                onChange={props.handleChange}
                notShowSearchAndAdd={true}
                customAction={{
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    width: 80,
                    // className:'space-nowrap',
                    render: (text, record) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title="View Clinics Ledger ">
                                <Button type="link" shape="circle" icon={<EyeOutlined />} onClick={() => goToClinicLedger(record)} />
                            </Tooltip>
                        </span>
                    ),
                }}
            />
        </Fragment>
    )
};

export default Ledger;
