import React, { useState, Fragment } from 'react';
import ManageAppointment from 'elements/manage-appointment';
import { gql, useQuery, useMutation } from 'services/graphQL';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tabs } from 'antd';
import { TableOutlined, CalendarOutlined } from '@ant-design/icons';
import { debounce } from "lodash";
import TableView from './components/table-view';
import CalanderView from 'elements/calander-view';
const { TabPane } = Tabs;

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const AppointmentList = (props) => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalData, setModalData] = useState({});
    const [calanderEvents, setCalanderEvents] = useState([]);
    const [tabClasses, setTabClasses] = useState('tableview');
    const [currentView, setCurrentView] = useState('table_view');
    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["createdAt", "DESC"]],
        sequelizeJson: {
            where: {
                'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
            }
        }
    });

    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRG9jdG9yLU1lZXRpbmciLCJ0eXBlIjoiQ29uc3RhbnQgVG9rZW4iLCJ1c2VySWQiOjF9.uQCCosoyhQ6fstarcn_aSIL_GGStVC0s8teRrtg7pfk';

    const { loading, data, refetch } = useQuery(gql`
        query findAndCountAppointments ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
            findAndCountAppointments (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                rows {
                    id
                    patientId
                    doctorId
                    patient { id user { id name phone}}
                    doctor { id user { id name }}
                    zoomMeetingId
                    meetingDate
                    startDate
                    startTime
                    createdAt
                    status
                    patientStatus
                    appointmentTypeId
                    isPaymentRequired
                    createdBy
                    shortMeetingId
                    isOnline
                }
                count
            }
        }
    `, { variables }
    );

    const channel = window.$pusher.subscribe('meeting_conference');

    channel.bind('joining', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                refetch()
                // data.findAndCountAppointments.rows[index].patientStatus = 'online';
            }
        }
    })

    channel.bind('left', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                // data.findAndCountAppointments.rows[index].patientStatus = 'offline';
                refetch()
            }
        }
    })


    const { data: users, refetch: refetchUsers } = useQuery(gql`
        query {
            patients {
                id
                createdBy
                user { id name }
            }
            doctors {
                id
                createdBy
                user { id name }
            }
            appointments {
                id
                startDate
                startTime
                createdBy
                doctorId
                patientId
            }
            mstAppointmentTypes {
                id
                name
            }
        }
    `);

    const [updateAppointment, { data: updatedData }] = useMutation(gql`
        mutation updateAppointment($id: ID!, $inputAppointment: AppointmentCreateInput!) {
            updateAppointment(id: $id, input: $inputAppointment) {
                id
            }
        }
    `);

    const [createAppointment, { data: createdData }] = useMutation(gql`
        mutation createAppointment($inputAppointment: AppointmentCreateInput!) {
            createAppointment(input: $inputAppointment) {
                id
            }
        }
    `);

    const [delAppointment, { data: deletedData }] = useMutation(gql`
        mutation deleteAppointment($id: ID!) {
            deleteAppointment(id: $id) {
                id
            }
        }
    `);

    if (createdData || updatedData || deletedData) {
        // setCalanderEvents([])
        refetch();
        refetchUsers();
    }

    const manageAppointment = (record) => {
        setModalData(record);
        setModalVisibility(true);
    }

    const deleteAppointment = (record) => {
        if (record && record.id) {
            delAppointment({
                variables: {
                    id: record.id
                }
            });
        }
    }

    const cancelAction = async (record) => {
        if (record && record.id) {
            const startTime = `2020-06-17 ${record.startTime}`
            const appointmentData = {
                doctorId: record.doctorId && parseInt(record.doctorId),
                patientId: record.patientId && parseInt(record.patientId),
                appointmentTypeId: record.appointmentTypeId && parseInt(record.appointmentTypeId),
                startDate: record.startDate ? moment(record.startDate).format('YYYY-MM-DD') : null,
                startTime,
                zoomMeetingId: record.zoomMeetingId && record.zoomMeetingId.toString(),
                createdBy: props.user && props.user.clinic ? props.user.clinic.id : null,
                isPaymentRequired: record.isPaymentRequired,
                status: 'cancelled'
            }

            updateAppointment({
                variables: {
                    id: record.id,
                    inputAppointment: appointmentData
                }
            });
            // const result = await window.$http.delete('zoom', record.zoomMeetingId);
        }
    }

    const createAndCheckUniqUrl = async () => {
        try {
            let shortUrl = window.$utility.createShortHash(9);
            const meetings = await window.$http.get('appointment', {
                where: {
                    shortMeetingId: shortUrl
                }
            });

            if (meetings.length) {
                return createAndCheckUniqUrl()
            } else {
                return shortUrl
            }

        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const createOrUpdate = async (event) => {
        if (!event.appointmentTypeId)
            return window.$utility.showErrorMessage('Appointment type is required!')
        const appointmentData = {
            doctorId: event.doctorId && parseInt(event.doctorId),
            patientId: event.patientId && parseInt(event.patientId),
            appointmentTypeId: event.appointmentTypeId && parseInt(event.appointmentTypeId),
            startDate: event.date ? moment(event.date).format('YYYY-MM-DD') : null,
            startTime: moment(event.time).format('YYYY-MM-DD hh:mm:ss a'),
            zoomMeetingId: event.zoomMeetingId && event.zoomMeetingId.toString(),
            createdBy: props.user && props.user.clinic ? props.user.clinic.id : null,
            isPaymentRequired: event.isPaymentRequired,
            isOnline: event.isOnline
        }

        appointmentData.status = event.isPaymentRequired ? 'scheduled' : 'confirmed'

        const startDate = moment(event.date).format('YYYY-MM-DD');
        const startTime = moment(event.time).format('HH:mm:ss');

        if (moment(startDate).isBefore(moment().subtract(1, 'days'))) {
            return window.$utility.showErrorMessage('The date is invalid.')
        }

        const formatedDate = moment(startDate + " " + startTime, ['YYYY-MM-DD HH:mm:ss']);
        appointmentData.meetingDate = formatedDate;

        if (!event.id) {

            const shortMeetingId = await createAndCheckUniqUrl();
            if (!shortMeetingId) {
                return window.$utility.showErrorMessage('Unable to create short meeting url! ')
            }

            appointmentData.shortMeetingId = shortMeetingId;

            const response = await fetch(`${process.env.REACT_APP_VIDEO_CONFERENCING_API_URL}create-meeting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                body: JSON.stringify({ meetingAt: formatedDate })
            });
            const result = await response.json();

            createAppointment({ variables: { inputAppointment: { ...appointmentData, ...{ zoomMeetingId: result.object.meetingId } } } })
        } else {

            const meeting = data.findAndCountAppointments.rows.find(a => a.id == event.id);

            if (!meeting) return window.$utility.showErrorMessage('Meeting not found!');

            const meetingId = meeting.zoomMeetingId;

            const response = await fetch(`${process.env.REACT_APP_VIDEO_CONFERENCING_API_URL}update-meeting/${meetingId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                body: JSON.stringify({ meetingAt: formatedDate })
            });
            const result = await response.json();

            updateAppointment({ variables: { id: event.id, inputAppointment: appointmentData } })
        }
        handleTabClick('table_view')
    }

    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [{
                        zoomMeetingId: {
                            $iLike: `%${value}%`
                        },
                    },
                    {
                        '$Patient.User.name$': {
                            $iLike: `%${value}%`
                        },
                    }, {
                        '$Patient.User.phone$': {
                            $iLike: `%${value}%`
                        },
                    }, {
                        '$Doctor.User.name$': {
                            $iLike: `%${value}%`
                        },
                    }
                    ],
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                },
                include: [
                    {
                        model: 'Patient',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                    {
                        model: 'Doctor',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {
        if (!Array.isArray(sorter)) sorter = [sorter];

        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        });
    };

    const onClose = () => {
        setModalVisibility(false);
    };


    const columns = [
        {
            title: 'Meeting Date',
            dataIndex: 'meetingDate',
            key: 'meetingDate',
            render: text => (text ? moment(text).format('MMM DD, YYYY hh:mm A') : 'NA'),
            sorter: true,
            className: 'space-nowrap',
            width: 165,
            align: 'left',
            showSorterTooltip: false
        },
        {
            title: 'Physician',
            dataIndex: '',
            key: 'doctor.id',
            align: 'left',
            render: value => <span className="cursor-pointer space-nowrap">{value.doctor ? value.doctor.user.name : 'NA'}</span>,
            sorter: false,
            width: 150,
        }, {
            title: 'Patient',
            dataIndex: '',
            key: 'patient.id',
            render: value => <span className="cursor-pointer space-nowrap">{value.patient ? value.patient.user.name : 'NA'}</span>,
            sorter: false,
            align: 'left',
            width: 150,
        },
        {
            title: 'Patient No.',
            dataIndex: 'patient',
            key: 'user',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            render: (text) => (text && text.user ? text.user.phone : 'NA'),
            width: 100
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: text => (text ? moment(text).format('MMM DD, YYYY hh:mm A') : 'NA'),
            sorter: true,
            className: 'space-nowrap',
            width: 165,
            align: 'left',
            showSorterTooltip: false
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <span className="capitalise space-nowrap">{text}</span>,
            sorter: true,
            align: 'left',
            width: 80,
            showSorterTooltip: false
        },
        {
            title: 'Patient Status',
            dataIndex: 'patientStatus',
            key: 'patientStatus',
            align: 'center',
            width: 150,
            sorter: true,
            className: 'space-nowrap',
            render: (text) => <span className={text == 'online' ? "text-success" : "text-danger"}>{capatilizeText(text)}</span>,
            showSorterTooltip: false
        },
        {
            title: 'Online/Offline',
            dataIndex: 'isOnline',
            key: 'isOnline',
            align: 'center',
            width: 150,
            sorter: true,
            className: 'space-nowrap',
            render: (text) => <span className={ text ? "text-success" : "text-danger"}>{capatilizeText(`${text ? 'Online' : 'Offline'}`)}</span>,
            showSorterTooltip: false
        },
    ];

    const handleDateClick = date => {
        const data = {
            startDate: date.dateStr,
            startTime: moment(date.dateStr).format('HH:mm:ss'),
            mode: 'add',
            isOnline: 'online'
        }
        manageAppointment(data)
    };

    const handleEventClick = calanderData => {
        const { event } = calanderData;
        const appointment = data.findAndCountAppointments.rows.find(a => a.id == event.id);

        manageAppointment(appointment)
    };

    const handleTabClick = (key) => {
        setCurrentView(key)
        if (key === 'table_view') {
            setTabClasses('tableview');
        } else if (key === 'calander_view') {
            handleCalanderEvents();
            setTabClasses('calendarview');
        }
    }

    const handleCalanderEvents = (appointmentData = {}) => {
        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {
            let events = data.findAndCountAppointments.rows.map(a => ({
                title: `${a.patient && a.patient.user ? a.patient.user.name : ''} - Dr. ${a.doctor && a.doctor.user ? a.doctor.user.name : ''}`,
                id: a.id,
                date: moment(a.meetingDate).format('YYYY-MM-DD'),
                start: new Date(a.meetingDate).toISOString(),
                end: new Date(a.meetingDate).toISOString(),
                // startStr: new Date(a.meetingDate).toISOString(),
                textColor: '#fff',
                status: a.status,
            }));

            events = events.filter(a => a.status !== 'cancelled')

            // if (appointmentData) {
            //     const doctor = users.doctors.find(a => a.id == appointmentData.doctorId)
            //     const patient = users.patients.find(a => a.id == appointmentData.patientId)

            //     const event = {
            //         title: `${patient ? patient.user.name : ''} - Dr. ${doctor ? doctor.user.name : ''}`,
            //         id: '',
            //         date: moment(appointmentData.meetingDate).format('YYYY-MM-DD'),
            //         textColor: '#fff',
            //         status: 'scheduled',
            //     }
            //     events.push(event)
            // }

            setCalanderEvents(events)
        }
    };

    const capatilizeText = (text) => {
        const name = text ? text.charAt(0).toUpperCase() + text.slice(1) : 'NA'
        return name
    };

    const tabs = [
        {
            title: 'Table View',
            icon: (<span><TableOutlined /> Table View</span>),
            content: (
                <TableView
                    appointments={data && data.findAndCountAppointments ? data.findAndCountAppointments : {}}
                    columns={columns}
                    manageAppointment={manageAppointment}
                    loading={loading}
                    debounceSearch={debounceSearch}
                    handleChange={handleChange}
                    cancelAction={cancelAction}
                    userName={props.user ? props.user.name : ''}
                    deleteAppointment={deleteAppointment}
                />
            ),
            key: 'table_view'
        },
        {
            title: 'Calander View',
            icon: (<span><CalendarOutlined /> Calander View </span>),
            content: (
                <CalanderView
                    calanderEvents={calanderEvents}
                    handleEventClick={handleEventClick}
                    handleDateClick={handleDateClick}
                />
            ),
            key: 'calander_view'
        }
    ];

    return (
        <Fragment>
            <div className={tabClasses}>
                <Tabs className="appointment-tab" activeKey={currentView} onTabClick={handleTabClick} type="card">
                    {
                        tabs.map(a => (
                            <TabPane tab={a.icon} key={a.key}>
                                {a.content}
                            </TabPane>
                        ))
                    }
                </Tabs>
                <ManageAppointment
                    {...props}
                    visible={modalVisibility}
                    onClose={onClose}
                    data={modalData}
                    patients={users && users.patients}
                    doctors={users && users.doctors}
                    appointments={users && users.appointments}
                    mstAppointmentTypes={users && users.mstAppointmentTypes}
                    onSubmit={createOrUpdate}
                    userId={props.user && props.user.clinic ? props.user.clinic.id : ''}
                    isPaymentAllowed={props.user && props.user.isPaymentAllowed}
                />
            </div>
        </Fragment>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentList);