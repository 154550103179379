import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery } from 'services/graphQL';
import { debounce } from "lodash";
import moment from 'moment';
import { Button, Tooltip, Popconfirm, Switch } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const Ledger = (props) => {

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]],
        sequelizeJson: {
            where: {
                'clinicId': props.user.clinic.id,
                'doctorId': props.match.params.doctorId,
            }
        }
    });

    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountOrders ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountOrders (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        doctorId
                        paymentBy
                        amount
                        razorOrderId
                        razorPaymentId
                        doctor {
                            id
                            user { name }
                        }
                        patient { id user { name } }
                        practiseAdmin { clinicName id }
                        createdAt
                    }
                    count
                }
            }
        `,
        {
            variables
        }
    );

    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        {
                            '$amount$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$Patient.User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$Doctor.User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        // {
                        //     '$razorOrderId$': {
                        //         $iLike: `%${value}%`
                        //     },
                        // },
                        // {
                        //     '$razorPaymentId$': {
                        //         $iLike: `%${value}%`
                        //     },
                        // },
                    ],
                    'clinicId': props.user.clinic.id,
                    'doctorId': props.match.params.doctorId,
                },
                include: [
                    {
                        model: 'Patient',
                        include: [{ model: 'User' }]
                    },
                    {
                        model: 'Doctor',
                        include: [{ model: 'User' }]
                    },
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'descend' ? 'DESC' : 'ASC'])),
            sequelizeJson: {
                where: {
                    'clinicId': props.user.clinic.id,
                    'doctorId': props.match.params.doctorId,
                }
            }
        });
    };

    const columns = [
        {
            title: 'Physician',
            dataIndex: 'doctor',
            key: 'user',
            align: 'left',
            sorter: false,
            render: (text) => (text && text.user && text.user.name ? text.user.name : 'NA'),
            width: 120,
            className: 'space-nowrap',
        },
        {
            title: 'Order Id.',
            dataIndex: 'razorOrderId',
            key: 'razorOrderId',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 180,
            className: 'space-nowrap',
        },
        {
            title: 'Transaction Id.',
            dataIndex: 'razorPaymentId',
            key: 'razorPaymentId',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 250,
            // className:'space-nowrap',
        },
        // {
        //     title: 'Doctor',
        //     dataIndex: 'doctor',
        //     key: 'user',
        //     align: 'left',
        //     sorter: false,
        //     render: (text) => (text && text.user && text.user.name ? text.user.name : 'NA'),
        //     width: 120,
        //     className: 'space-nowrap',
        // },
        {
            title: 'Patient',
            dataIndex: 'patient',
            key: 'user',
            align: 'left',
            sorter: true,
            width: 100,
            render: (text) => (text && text.user && text.user.name ? text.user.name : 'NA'),
            className: 'space-nowrap',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'left',
            sorter: true,
            width: 80,
            render: (text) => (text ? text : 'NA'),
            className: 'space-nowrap',
        },
        {
            title: 'Created On',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'left',
            sorter: true,
            width: 150,
            render: (text) => (text ? moment(text).format('DD MMM, YYYY  hh:mm A') : 'NA'),
            className: 'space-nowrap',
        }
    ];

    return (
        <Fragment>
            <DataTable
                title="Ledger"
                data={data && data.findAndCountOrders && data.findAndCountOrders.rows ? data.findAndCountOrders.rows : null}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={data && data.findAndCountOrders && data.findAndCountOrders.count ? data.findAndCountOrders.count : 0}
                loading={loading}
                onChange={handleChange}
                notShowSearchAndAdd={true}
                customAction={'null'}
            />
        </Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Ledger);
