import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomModal from 'elements/custom-modal';
import FormFieldInput from 'elements/form-field-input';
import { Button } from 'antd';
import moment from 'moment';

export default (props) => {
    const defaultValues = {
        id: null,
        dob: null,
        gender: null,
    };

    const { handleSubmit, register, setValue, errors, control } = useForm({ defaultValues });
    const [title, setModalTitle] = useState('Patient Details');
    const [userId, setUserId] = useState(null)

    useEffect(() => {

        setModalTitle('Add Patient');
        if (props.data && props.data.id) {
            let date = null
            if (props.data && props.data.dob) {
                date = moment(props.data.dob).format('YYYY-MM-DD');
            }
            setModalTitle('Edit Patient');
            setTimeout(() => {
                setValue('name', props.data.user ? props.data.user.name : '');
                setValue('email', props.data.user ? props.data.user.email : '');
                setValue('phone', props.data.user ? props.data.user.phone : '');
                setValue('userId', props.data.user ? props.data.user.id : '');
                setValue('gender', props.data.gender);
                setValue('id', props.data.id);
                setValue('dob', date ? moment(date) : null);
                setUserId(props.data.user ? props.data.user.id : null)
            });
        } else {
            setValue('name', null);
            setValue('email', null);
            setValue('phone', null);
            setValue('gender', null);
            setValue('dob', null);
        }
    }, [props.visible, props.data]);

    const onSubmit = (data) => {
        if (props.data.mode === 'edit') {
            data.id = props.data.id
            data.userId = userId
        }
        props.onSubmit(data)
        props.onClose();
    };

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().subtract(1, 'days');
    }

    return (
        <CustomModal
            title={title}
            centered
            showModal={props.visible}
            width={props.width}
            onClose={props.onClose}
        >
            <div className="container">
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                    <div className="requred-feild">
                        <FormFieldInput
                            inputType="text-name"
                            placeholder="Enter Patient Name"
                            attribute="name"
                            showSearch={true}
                            control={control}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            label="Patient Name"
                            rules={{
                                required: 'Patient Name is required',
                            }}
                        />
                    </div>
                    <div className="requred-feild">
                        <FormFieldInput
                            inputType="text-number"
                            placeholder="Enter Mobile Number"
                            attribute="phone"
                            control={control}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            label="Mobile Number"
                            maxLength={10}
                            minLength={10}
                            rules={{
                                required: 'Mobile Number is required',
                                minLength: {
                                    value: 10,
                                    message: 'Invalid Number (Length should be 10)'
                                }
                            }}
                        />
                    </div>
                    <div className="requred-feild">
                        <FormFieldInput
                            inputType="select"
                            placeholder="Select Gender"
                            attribute="gender"
                            control={control}
                            register={register}
                            errors={errors}
                            showSearch={true}
                            setValue={setValue}
                            label="Select Gender"
                            options={[{ "label": "Male", "value": "male" }, { "label": "Female", "value": "female" }, { "label": "Others", "value": "others" }]}
                            rules={{
                                required: 'Gender is required',
                            }}
                        />
                    </div>
                    <FormFieldInput
                        inputType="email"
                        placeholder="Enter Email"
                        attribute="email"
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        label="Email"
                        showEmailError={false}
                    />
                    {/* <FormFieldInput
                        inputType="date-picker"
                        placeholder="Enter Date of Birth (In years)"
                        attribute="dob"
                        format='YYYY-MM-DD'
                        control={control}
                        errors={errors}
                        register={register}
                        label="Date of Birth"
                        setValue={setValue}
                        disabledDate={disabledDate}
                        style={{ width: '100%' }}
                    /> */}
                    <div className="text-right">
                        <Button key="submit" type="primary" htmlType="submit">{props.data.mode === 'edit' ? 'Update' : 'Add'}</Button>
                        {/* <Button  type="primary" onClick={() => props.onClose()}>Close</Button> */}
                    </div>
                </form>
            </div>
        </CustomModal>

    );
};
