import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomModal from 'elements/custom-modal';
import FormFieldInput from 'elements/form-field-input';
import { Button } from 'antd';
import moment from 'moment';

export default (props) => {

    const { handleSubmit, setValue, register, errors, control } = useForm();

    useEffect(() => {
        setValue('transactionId', null)
        setValue('amount', null)
        setValue('context', null)
        setValue('date', '')
        setValue('time', '')
    }, [props]);

    const onSubmit = (data) => {
        data.appointmentId = props.data.appointmentId;
        data.clinicId = props.data.clinicId;

        const startDate = moment(data.date, ['YYYY-MM-DD']).format('YYYY-MM-DD');
        const startTime = moment(data.time, ['hh:mm:ss']).format('hh:mm:ss A');
        let formatedDate = `${startDate} ${startTime}`;
        formatedDate = new Date(formatedDate);
        data.transferedOn = formatedDate

        props.onSubmit(data)
        props.onClose();
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().subtract(0, 'days');
    }


    return (
        <CustomModal
            title='Add Transaction'
            centered
            showModal={props.visible}
            width={props.width}
            onClose={props.onClose}
        >
            <div className="container">
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <div className="col-sm-12">
                        <FormFieldInput
                            placeholder="Enter Transaction Id"
                            attribute="transactionId"
                            control={control}
                            register={register}
                            setValue={setValue}
                            showSearch={true}
                            errors={errors}
                            label="Transaction Id"
                            rules={{
                                // required: 'Note is required',
                            }}
                        />
                    </div>
                    <div className="col-sm-12">
                            <FormFieldInput
                                inputType="date-picker"
                                placeholder="Select transaction date"
                                attribute="date"
                                register={register}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                disabledDate={disabledDate}
                                format='MMM DD, YYYY'
                                label="Transaction Date"
                                style={{ "width": "100%" }}
                                rules={{
                                    required: 'Transaction Date is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            <FormFieldInput
                                inputType="time-picker"
                                placeholder="Select transaction Time"
                                attribute="time"
                                register={register}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                minuteStep={15}
                                label="Transaction Time"
                                rest={{
                                    format: 'HH:mm',
                                    minuteStep: 15
                                }}
                                // format="hh:mm a"
                                style={{ "width": "100%" }}
                                rules={{
                                    required: 'Transaction Time is required',
                                }}
                            />
                        </div>
                    <div className="col-sm-12">
                        <FormFieldInput
                            placeholder="Enter Amount"
                            attribute="amount"
                            control={control}
                            setValue={setValue}
                            register={register}
                            showSearch={true}
                            errors={errors}
                            label="Enter Amount"
                            rules={{
                                required: 'Amount is required',
                            }}
                        />
                    </div>
                    <div className="col-sm-12">
                        <FormFieldInput
                            inputType="textarea"
                            placeholder="Enter Note"
                            attribute="context"
                            control={control}
                            register={register}
                            setValue={setValue}
                            showSearch={true}
                            errors={errors}
                            label="Clinic Note"
                            rules={{
                                required: 'Note is required',
                            }}
                        />
                    </div>

                    <div className="col-sm-12 text-right">
                        <Button key="submit" type="primary" htmlType="submit">{props.data.mode === 'edit' ? 'Update' : 'Add'}</Button>
                        {/* <Button type="primary" onClick={() => props.onClose()}>Close</Button> */}
                    </div>
                    </div>
                </form>
            </div>
        </CustomModal>

    );
};
