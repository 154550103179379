import React from 'react';
import LinkInline from 'elements/link-inline';

const Footer = (props) => (
    <div>
         {/* <div className="txt1 text-center pt-2 pb-3">
             <span>
                 Or Log In with
             </span>
         </div>
         <div className="flex-c-m">
             <a href="/" className="login100-social-item bg1">
                 <i className="fa fa-facebook"></i>
             </a>
             <a  href="/" className="login100-social-item bg3">
                 <i className="fa fa-google"></i>
             </a>
         </div> */}
         {/* <div className="text-center pt-2 signup-text">
             <span className="txt1">
                 Dont have an account
             </span>
             <span className="ml-1">
                { 
                    props.type === "register" ? <strong><LinkInline label="Sign in" route="login" /></strong>
                    : <strong><LinkInline label="Sign Up" route="register" /></strong>
                }   
             </span>
         </div> */}
    </div>
);

export default Footer;