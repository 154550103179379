import React from 'react'
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Popconfirm, Switch, Tabs } from 'antd';
import DataTable from 'elements/data-table';

const TableView = (props) => {

    const appointments = props.appointments;
    const columns = props.columns;

    return (
        <DataTable
            title="Appointment"
            data={appointments && appointments.rows ? appointments.rows : []}
            columns={columns}
            totalRecords={appointments && appointments.count ? appointments.count : 0}
            loading={props.loading}
            debounceSearch={props.debounceSearch}
            onChange={props.handleChange}
            notShowSearchAndAdd={true}
            customAction={'null'}
        />
    )
}
export default TableView