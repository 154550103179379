import lodash from 'lodash';
import { message } from 'antd';
import Pusher from 'pusher-js';
import countries from "utils/country.json";

message.config({
    top: 24,
    duration: 3,
    maxCount: 1,
});

const service = {
    redirectToHttps() {
        const loc = `${window.location.href} `;
        if (loc.indexOf('http://') === 0 && process.env.NODE_ENV === 'production') {
            console.log('redirection', process.env.NODE_ENV);
            window.location.href = loc.replace('http://', 'https://');
        }
    },
    downloadFile(url, fileName = '') {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    goToPage(name = 'home', data = {}, type = 'query') {
        const obj = { name };
        if (!lodash.isEmpty(data)) obj[type] = data;
        // Vue.router.push(obj);
    },
    redirectToLink(url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    showSucessMessage(msg, duration = 3) {
        if (!message) return;
        message.success(msg, duration);
    },
    showWarningMessage(msg, duration = 3) {
        if (!message) return;
        message.warn(msg, duration);
    },
    showErrorMessage(msg, duration = 3) {
        if (!message) return;
        message.error(msg, duration);
    },
    showInfoMessage(msg, duration = 3) {
        if (!message) return;
        message.info(msg, duration);
    },
    getCountries() {
        const country = countries.map(a => ({value: a.country, label: a.country}))
        return country
    },
    createShortHash(len) {
        const VALUES = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqurstuvwxyz';
        let short_url = '';
        for(let i = len; i>0; i--) {
            short_url += VALUES[Math.floor(Math.random() * VALUES.length)]; 
        }
        return short_url;
    }
};

Pusher.logToConsole = true;
const pusher = new Pusher('853a91361b1fcf5b423d', {
    cluster: 'ap2',
    forceTLS: true
});

window.$pusher = pusher;

window.$utility = service;

export default service;
