import React from 'react'
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Popconfirm, Switch, Tabs } from 'antd';
import DataTable from 'elements/data-table';

const TableView = (props) => {

    const appointments = props.appointments;
    const columns = props.columns;

    return (
        <DataTable
            title="Appointment"
            data={appointments && appointments.rows ? appointments.rows : []}
            columns={columns}
            createOrUpdateAction={props.manageAppointment}
            deleteAction={props.deleteAppointment}
            totalRecords={appointments && appointments.count ? appointments.count : 0}
            loading={props.loading}
            debounceSearch={props.debounceSearch}
            onChange={props.handleChange}
            customAction={{
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 90,
                // className: 'space-nowrap',
                render: (text, record) => (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Edit">
                            <Button
                                type="link"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={() => props.manageAppointment(record, 'edit')}
                                disabled={record.status == "cancelled" || record.status == "completed"}
                            // disabled={record.status.includes(["completed", "cancelled"])}
                            />
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <Popconfirm disabled={record.status == "cancelled" || record.status == "completed"} title="Sure to cancel the appointment?" okText="Yes" cancelText="No" onConfirm={() => props.cancelAction(record)}>
                                <Button
                                    type="link-danger"
                                    shape="circle"
                                    icon={<CloseCircleOutlined />}
                                    disabled={record.status == "cancelled" || record.status == "completed"}
                                />
                            </Popconfirm>
                        </Tooltip>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Button disabled={record.status !== 'confirmed'} disabled={record.status !== 'confirmed'} type="link" title="Join Meeting" shape="circle">
                                <a href={`${process.env.REACT_APP_VIDEO_CONFERENCING_BASE_URL}?sessionid=${record.zoomMeetingId}&admin=true&userFullName=${props.userName}`} target="_blank">
                                    Join Meeting
                                </a>
                            </Button>
                        </span>
                    </span>
                ),
            }}
        />
    )
}
export default TableView