import React, { useState, useEffect, Fragment } from 'react';
import { gql, useQuery } from 'services/graphQL';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tabs } from 'antd';
import { TableOutlined, CalendarOutlined } from '@ant-design/icons';
import { debounce, uniq } from "lodash";

import Ledger from './components/ledger';
import AmountRecieved from './components/amount-recieved';

const { TabPane } = Tabs;

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const LedgerList = (props) => {
    const [tabClasses, setTabClasses] = useState('tableview');
    const [currentView, setCurrentView] = useState('ledger_list');
    const [ledgerData, setLedgerData] = useState({
        rows: [],
        count: 0
    });
    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["createdAt", "DESC"]],
        sequelizeJson: {
            where: {
                'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
            }
        }
    });

    const [transactionVariable, setTransactionVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["createdAt", "DESC"]],
        sequelizeJson: {
            where: {
                'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
            }
        }
    });

    const { loading, data, refetch } = useQuery(gql`
    query findAndCountOrders ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
        findAndCountOrders (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
            rows {
                id
                doctorId
                paymentBy
                clinicId
                amount
                appointmentId
                practiseAdmin { clinicName id }
                doctor { user { name } }
                createdAt
            }
            count
        }
    }
    `, { variables }
    );

    const { loading: transactionLoading, data: transactions, refetch: refetchTransactions } = useQuery(gql`
    query findAndCountTransactions ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
        findAndCountTransactions (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
            rows {
                context
                amount
                transactionId
                transferedOn
            }
            count
        }
    }
    `, { variables: transactionVariable }
    );

    useEffect(() => {
        setTotalAmountData()
    }, [data])

    const ledgerSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        {
                            '$amount$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$Doctor.User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ],
                    'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
                },
                include: [
                    {
                        model: 'Doctor',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                ]
            }
        });
    }, 500);

    const amountRecievedSearch = debounce((value) => {
        setTransactionVariables({
            ...transactionVariable,
            sequelizeJson: {
                where: {
                    $or: [
                        {
                            '$amount$': {
                                $iLike: `%${value}%`
                            },
                        },
                        // {
                        //     '$transactionId$': {
                        //         $iLike: `%${value}%`
                        //     },
                        // },
                        {
                            '$context$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ],
                    'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
                },
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {
        if (!Array.isArray(sorter)) sorter = [sorter];

        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        });

        setTransactionVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'clinicId': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        });
    };

    const setTotalAmountData = () => {
        if (data && data.findAndCountOrders && data.findAndCountOrders.rows) {

            let uniqDoctor = data.findAndCountOrders.rows.map(a => a.doctorId)
            uniqDoctor = uniq(uniqDoctor)

            let clinicTransactions = []
            for (const doctorId of uniqDoctor) {
                let clinictotalAmount = data.findAndCountOrders.rows.filter(a => a.doctorId == doctorId)
                    .map(a => +a.amount).reduce((a, b) => a + b);

                clinicTransactions.push({
                    doctorId,
                    amount: clinictotalAmount.amount ? +clinictotalAmount.amount : clinictotalAmount,
                    appointmentId: data.findAndCountOrders.rows.find(a => a.doctorId == doctorId).appointmentId
                })
            };
            clinicTransactions = clinicTransactions.map(a => ({
                ...a,
                doctorName: data.findAndCountOrders.rows.find(b => b.doctorId == a.doctorId)?.doctor?.user?.name,
                // orderId: data.findAndCountOrders.rows.find(b => b.doctorId == a.doctorId).id,
                doctorId: a.doctorId,
                appointmentId: a.appointmentId
            }));

            setLedgerData({
                rows: clinicTransactions,
                count: clinicTransactions.length
            })
        }
    };


    const handleTabClick = (key) => {
        setCurrentView(key)
        if (key === 'table_view') {
            setTabClasses('tableview');
        } else if (key === 'calander_view') {
            setTabClasses('calendarview');
        }
    }

    const ledgerColumns = [
        {
            title: 'Physician',
            dataIndex: 'doctorName',
            key: 'doctorName',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 150,
            className: 'space-nowrap',
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'left',
            sorter: false,
            render: (text) => (text ? `₹ ${text}` : 'NA'),
            width: 160,
            className: 'space-nowrap',
        },
    ];

    const recievedColumns = [
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 150,
            className: 'space-nowrap',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'left',
            sorter: true,
            render: (text) => (text ? `₹ ${text}` : 'NA'),
            width: 160,
            className: 'space-nowrap',
            showSorterTooltip: false
        },
        {
            title: 'Date',
            dataIndex: 'transferedOn',
            key: 'transferedOn',
            align: 'left',
            sorter: false,
            render: (text) => (text ? moment(text).format('DD MMM, YYYY, hh:mm A') : 'NA'),
            width: 160,
            className: 'space-nowrap',
        },
        {
            title: 'Context',
            dataIndex: 'context',
            key: 'context',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 160,
            className: 'space-nowrap',
        },
    ];

    const tabs = [
        {
            title: 'Ledger List',
            icon: (<span><TableOutlined /> Ledger List</span>),
            content: (
                <Ledger
                    data={ledgerData}
                    columns={ledgerColumns}
                    // manageTransfer={manageTransfer}
                    loading={loading}
                    debounceSearch={ledgerSearch}
                    handleChange={handleChange}
                    {...props}
                />
            ),
            key: 'ledger_list'
        },
        {
            title: 'Amount Recieved',
            icon: (<span><CalendarOutlined /> Amount Recieved </span>),
            content: (
                <AmountRecieved
                    data={transactions}
                    debounceSearch={amountRecievedSearch}
                    columns={recievedColumns}
                    handleChange={handleChange}
                    loading={transactionLoading}
                    {...props}
                />
            ),
            key: 'amount_recieved_list'
        }
    ];

    return (
        <Fragment>
            <div className={tabClasses}>
                <Tabs className="appointment-tab" activeKey={currentView} onTabClick={handleTabClick} type="card">
                    {
                        tabs.map(a => (
                            <TabPane tab={a.icon} key={a.key}>
                                {a.content}
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        </Fragment>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LedgerList);