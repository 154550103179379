import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from 'store';

export default ({ children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={
                ({ location }) => {
                    const currentState = store.getState();

                    if (
                        currentState
                        && currentState.auth
                        && currentState.auth.user
                        && currentState.auth.user.userRole
                        && currentState.auth.user.userRole.name
                        && currentState.auth.user.userRole.name === 'doctor'
                    ) {
                        return <Redirect
                        to={{
                            pathname: `${currentState.auth.user.userRole.name}/appointment`,
                            state: { from: location }
                        }}
                    />
                    }
                    else if (
                        currentState
                        && currentState.auth
                        && currentState.auth.user
                        && currentState.auth.user.userRole
                        && currentState.auth.user.userRole.name
                        && ['admin', 'superAdmin'].includes(currentState.auth.user.userRole.name)
                    ) {
                        return <Redirect
                        to={{
                            pathname: `${currentState.auth.user.userRole.name}`,
                            state: { from: location }
                        }}
                    />
                    }
                    return children;
                }
            }
        />
    );
}
