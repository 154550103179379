import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mt-4">
            <h1> Privacy Policy Details</h1>
            <p>We value the trust you place in RxOnCall. That’s why we insist upon the highest standards
            for customer information privacy and transactions.
            </p>
            <p>
                Note: Our privacy policy is subject to change at any time without notice.
            </p>
            <div>
                <h4>
                    1. Collection of Personally Identifiable Information
                </h4>
                <p>
                    We collect personally identifiable information (name phone number, email etc.) from you
                    when you request for a service. We do not for any reason whatsoever sell your Personal
                    Identifiable Information to any third party or otherwise trade on it.
                </p>
            </div>
            <div>
                <h4>
                    2. Use of Demographic and Profile Data
                </h4>
                <p>
                    We use personal information to provide the services you request. We use your personal
                    information to troubleshoot problems; collect fees owed; measure interest in our products and
                    services, inform you about online and offline offers and customize your experience. In our
                    efforts to continually improve our product and service offerings, we collect and analyse
                    demographic and profile data about our users’ activity on our website. We identify and use
                    your IP address to help diagnose problems with our server, and to administer our website.
                </p>
            </div>
            <div>
                <h4>
                    3. Provision of Aggregate Demographic Information to Third Parties
                </h4>
                <p>
                    RxOnCall reserves the right to provide aggregate demographic information of its member
                    base to third parties. We do not shared personal information of individual users with third
                    parties.
                </p>
            </div>
            <div>
                <h4>
                    4. Cookies
                </h4>
                <p>
                    A “cookie” is a small piece of information stored by a Web server on a Web browser so it can
                    be later read back from that browser. Cookies are useful for enabling the browser to
                    remember information specific to a given user. RxOnCall may place both permanent and
                    temporary cookies in your computer’s hard drive. RxOnCall cookies do not contain any of
                    your personally identifiable information.
                </p>
            </div>
            <div>
                <h4>
                    5. Email Communications
                </h4>
                <p>
                    RxOnCall will communicates with you through the email you have specified. Your email
                    address is never shared with anybody within or outside RxOnCall. RxOnCall uses this email
                    address to send you two types of communications:
                    <ul>
                        <li>
                            Sending you newsletters that you have subscribed to.
                        </li>
                        <li>
                            Sending you occasional announcements about changes in RxOnCall policies or
                            announcement of new promotions or services.

                        </li>
                    </ul>
                </p>
            </div>
            <div>
                <h4>
                    6. App Usage
                </h4>
                <p>
                    We may disclose to third party services certain personally identifiable information listed
                    below:
                    <ul>
                        <li>
                            Information you provide us such as name, email, mobile phone number
                        </li>
                        <li>
                            Information we collect as you access and use our service, including device
                            information, location and network carrier
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
