import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { showLoader, hideLoader } from 'services/loader';
import { store } from 'store';
import { logout } from 'store/auth/action';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

// add the authorization to the headers
const interceptor = new ApolloLink((operation, forward) => {
    showLoader();
    if (localStorage.getItem('x-access-token')) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'x-access-token': localStorage.getItem('x-access-token'),
            }
        }));
    }

    return forward(operation).map((response) => {
        hideLoader();
        return response;
    });
});

const errorHandler = onError(({ graphQLErrors }) => {
    let message = 'Something went wrong!';
    hideLoader();
    if (graphQLErrors && graphQLErrors.length) {
        const errMessages = graphQLErrors.map(x => x.message);
        if (
            errMessages.includes('Access denied!') || errMessages.includes('Context creation failed: jwt expired')
        ) {
            store.dispatch(logout());
            window.location.href = '/';
        }
        message = graphQLErrors.map(x => (x.message)).join(', ');
    }
    window.$utility.showErrorMessage(message);
});

const graphqlClient = new ApolloClient({
    link: from([
        interceptor,
        errorHandler,
        httpLink
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    }
});

export {
    graphqlClient,
    useQuery,
    gql,
    useMutation,
    useLazyQuery
};
