import React, { useState } from 'react';
import { Table, Button, Tooltip, Input, Popconfirm } from 'antd';
import { EditOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Search } = Input;

export default (props) => {
    const [search, setSearch] = useState()
    const [selectedPageSize, setSelectedPageSize] = useState(10)

    let columns = [...props.columns];
    if (props.customAction) {
        if (props.customAction === 'null') columns = [...columns];
        else columns = [...columns, props.customAction];
    } else {
        columns = [...columns, {
            title: 'Action',
            key: 'action',
            align: 'right',
            width: 120,
            // className:'space-nowrap',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title={"Edit " + props.title}>
                        <Button type="link" shape="circle" icon={<EditOutlined />} onClick={() => props.createOrUpdateAction(record)} />
                    </Tooltip>
                    <Tooltip title={"Delete " + props.title}>
                        <Popconfirm title="Sure to delete?" onConfirm={() => props.deleteAction(record)}>
                            <Button type="link-danger" shape="circle" icon={<CloseCircleOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </span>
            ),
        }];
    }

    const getPatientSearch = () => {
        setSearch(null)
        props.getPatientList()
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setSelectedPageSize(pagination.pageSize)
        props.onChange(pagination, filters, sorter, extra)
    }

    return (
        <div>

            <div className="pb-2">
                <div className="row">
                    {
                        props.debounceSearch &&
                        <div className={`${props.title === 'Patient' ? 'col-6' : 'col-6'}  text-left`}>
                            <Search className={`${props.title === 'Patient' ? 'search-bar-long' : ''}`} placeholder={"Search " + props.title} allowClear={true} onChange={event => { setSearch(event.target.value); props.debounceSearch(event.target.value) }} value={search} style={{ width: 200 }} />
                        </div>
                    }
                    {
                        props.notShowSearchAndAdd ? '' :
                            (
                                <div className={`${props.debounceSearch ? 'col-6' : 'col-12'}  text-right`}>
                                    <div className={`${props.title === 'Patient' ? 'd-flex justify-content-between' : ''}`}>
                                        {
                                            props.title === 'Patient' &&
                                            <div>
                                                <Button type="primary" onClick={() => getPatientSearch()}> Get Your Patient </Button>
                                            </div>
                                        }
                                        <Tooltip title={"Add " + props.title}>
                                            <Button
                                                type={props.buttonType ? props.buttonType : 'primary'}
                                                // icon={props.icon ? props.icon : <PlusOutlined />}
                                                onClick={() => props.createOrUpdateAction(null)}
                                                size={props.buttonSize ? props.buttonSize : 'default'}>
                                                {"Add " + props.title}
                                                <PlusOutlined />
                                            </Button>
                                        </Tooltip>

                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
            <div className="pt-1">
                <Table
                    scroll={{ x: 900 }}
                    loading={props.loading}
                    onChange={handleTableChange}
                    rowKey="id"
                    size="small"
                    pagination={{
                        total: props.totalRecords,
                        // defaultPageSize: 10,
                        pageSize: selectedPageSize,
                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    dataSource={props.data}
                    columns={columns}
                />
            </div>
        </div>
    );
}
