import {
    Clinic,
    Patient,
    ChangePassword,
    Doctor,
    Appointment,
    Ledger,
    LedgerDetail
} from 'pages/admin/component';

const url = '/admin';

export default [
    {
        url: `${url}/clinic-list`,
        name: 'Dashboard',
        component: Clinic
    },
    {
        url: `${url}/change-password`,
        name: 'Change Password',
        component: ChangePassword
    },
    {
        url: `${url}/patient-list/:userId`,
        name: 'Patient List',
        component: Patient
    },
    {
        url: `${url}/physician-list/:userId`,
        name: 'Physician List',
        component: Doctor
    },
    {
        url: `${url}/appointment-list/:userId`,
        name: 'Appointment List',
        component: Appointment
    },
    {
        url: `${url}/ledger`,
        name: 'Ledger Management',
        component: Ledger
    },
    {
        url: `${url}/ledger-detail/:clinicId`,
        name: 'Ledger Details',
        component: LedgerDetail
    },
];
