import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery } from 'services/graphQL';
import { Tabs } from 'antd';
import { TableOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from "lodash";
import TableView from './components/table-view';
import CalanderView from 'elements/calander-view';
const { TabPane } = Tabs;

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const DoctorDashboard = (props) => {
    const [currentView, setCurrentView] = useState('table_view');
    const [calanderEvents, setCalanderEvents] = useState([]);
    const [tabClasses, setTabClasses] = useState('tableview');

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["createdAt", "DESC"]],
        sequelizeJson: {
            where: {
                'doctorId': props.user.doctor ? props.user.doctor.id : null
            }
        }
    });

    const { loading, data, refetch } = useQuery(
        gql`
        query findAndCountAppointments ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
            findAndCountAppointments (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                rows {
                    id
                    zoomMeetingId
                    meetingDate
                    status
                    createdAt
                    patientStatus
                    patient {
                        id
                        dob
                        userId
                        user {
                        name
                        phone
                        email
                        }
                    }
                    doctor { id user { name } }
                }
                count
            }
        }
        `,
        {
            variables
        }
    );

    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        // {
                        //     'status': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        // {
                        //     'zoomMeetingId': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        // {
                        //     '$Patient.dob$': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        {
                            '$Patient.User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$Patient.User.email$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$Patient.User.phone$': {
                                $iLike: `%${value}%`
                            },
                        }
                    ],
                    'doctorId': props.user.doctor ? props.user.doctor.id : null
                },
                include: [
                    {
                        model: 'Patient',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                    {
                        model: 'Doctor',
                        where: {
                            'userId': props.user.id
                        },
                        include: [
                            {
                                model: 'User',
                            }
                        ]
                    },
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'doctorId': props.user.doctor ? props.user.doctor.id : null
                }
            }
        });
    };

    const channel = window.$pusher.subscribe('meeting_conference');

    channel.bind('joining', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                refetch()
                // data.findAndCountAppointments.rows[index].patientStatus = 'online';
            }
        }
    })

    channel.bind('left', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                // data.findAndCountAppointments.rows[index].patientStatus = 'offline';
                refetch()
            }
        }
    })

    const handleTabClick = (key) => {
        setCurrentView(key)
        if (key === 'table_view') {
            setTabClasses('tableview');
        } else if (key === 'calander_view') {
            handleCalanderEvents();
            setTabClasses('calendarview');
        }
    };

    const handleCalanderEvents = (appointmentData = {}) => {
        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {
            console.log(data.findAndCountAppointments.rows)
            let events = data.findAndCountAppointments.rows.map(a => ({
                title: `${a.patient && a.patient.user ? a.patient.user.name : ''} - Dr. ${a.doctor && a.doctor.user ? a.doctor.user.name : ''}`,
                id: a.id,
                date: moment(a.meetingDate).format('YYYY-MM-DD'),
                start: new Date(a.meetingDate).toISOString(),
                end: new Date(a.meetingDate).toISOString(),
                // startStr: new Date(a.meetingDate).toISOString(),
                textColor: '#fff',
                status: a.status,
            }));

            events = events.filter(a => a.status !== 'cancelled')
            setCalanderEvents(events)
        }
    };

    const columns = [
        // {
        //     title: 'Meeting ID',
        //     dataIndex: 'zoomMeetingId',
        //     key: 'zoomMeetingId',
        //     align: 'left',
        //     sorter: true,
        //     className:'space-nowrap',
        //     width:130
        //     // render: (text) => (text ? moment(text).format('YYYY-MM-DD') : 'NA')
        // },
        {
            title: 'Meeting Date',
            dataIndex: 'meetingDate',
            key: 'meetingDate',
            render: text => (text ? moment(text).format('MMM DD, YYYY HH:mm A') : 'NA'),
            sorter: true,
            align: 'left',
            className: 'space-nowrap',
            width: 150,
            showSorterTooltip: false
        },
        // {
        //     title: 'Start Time',
        //     dataIndex: 'startTime',
        //     key: 'startTime',
        //     align: 'center',
        //     sorter: true,
        // className:'space-nowrap',
        //     render: (text) => (text ? moment(`2020-04-12 ${text}`).format('HH:mm A') : 'NA')
        // },
        {
            title: 'Patient Name',
            dataIndex: 'patient',
            key: 'user',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            render: (text) => (text && text.user ? text.user.name : 'NA'),
            width: 150
        },
        {
            title: 'Phone No.',
            dataIndex: 'patient',
            key: 'user',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            render: (text) => (text && text.user ? text.user.phone : 'NA'),
            width: 90
        },
        {
            title: 'DOB',
            dataIndex: 'patient',
            key: 'dob',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            render: (text) => (text && text.dob ? moment(text.dob).format('DD MMM, YYYY') : 'NA'),
            width: 90
        },
        {
            title: 'Meeting Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            width: 110,
            sorter: true,
            className: 'space-nowrap',
            render: (text) => (text ? text.toUpperCase() : 'NA'),
            showSorterTooltip: false
        },
        {
            title: 'Join Status',
            dataIndex: 'patientStatus',
            key: 'patientStatus',
            align: 'center',
            width: 90,
            sorter: false,
            className: 'space-nowrap',
            render: (text) => <span className={text == 'online' ? "text-success" : "text-danger"}>{capatilizeText(text)}</span>
        },
    ];

    const capatilizeText = (text) => {
        const name = text ? text.charAt(0).toUpperCase() + text.slice(1) : 'NA'
        return name
    };

    const tabs = [
        {
            title: 'Table View',
            icon: (<span><TableOutlined /> Table View</span>),
            content: (
                <TableView
                    appointments={data && data.findAndCountAppointments ? data.findAndCountAppointments : {}}
                    columns={columns}
                    loading={loading}
                    debounceSearch={debounceSearch}
                    handleChange={handleChange}
                    userName={props.user ? props.user.name : ''}
                />
            ),
            key: 'table_view'
        },
        {
            title: 'Calander View',
            icon: (<span><CalendarOutlined /> Calander View </span>),
            content: (
                <CalanderView
                    calanderEvents={calanderEvents}
                />
            ),
            key: 'calander_view'
        }
    ];

    return (
        <Fragment>
            <div className={tabClasses}>
                <Tabs className="appointment-tab" activeKey={currentView} onTabClick={handleTabClick} type="card">
                    {
                        tabs.map(a => (
                            <TabPane tab={a.icon} key={a.key}>
                                {a.content}
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        </Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDashboard);
