import React from 'react';
import { MedicineBoxOutlined, StockOutlined , UsergroupAddOutlined, MessageOutlined } from '@ant-design/icons';

export default {
    items: [
        {
            title: 'Appointments',
            url: '/clinic-admin/manage-appointment',
            icon: <MessageOutlined />,
        },
        {
            title: 'Patients',
            url: '/clinic-admin/patient',
            icon: <UsergroupAddOutlined />,
        },
        {
            title: 'Physicians',
            url: '/clinic-admin/physician',
            icon: <MedicineBoxOutlined />,
        },
    ],
};
