import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import FormLayout from 'layouts/form-layout';
import LinkInline from 'elements/link-inline';
import FormFieldInput from 'elements/form-field-input';
import Button from 'elements/button';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const ChangePassword = (props) => {

    const defaultValues = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    };

    const { handleSubmit, control, errors, getValues } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        try {
            await window.$http.patch('/user/change-password', {
                newPassword: data.password,
                oldPassword: data.oldPassword
            });

            window.$utility.showSucessMessage("Password successfully updated");

            const route = props.user.userRole.name === 'doctor' ? "physician" : props.user.userRole.name

            props.history.push(`/${route}/`);
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };

    return (
        <Fragment>
            <form className="container-fluid" onSubmit={handleSubmit(onSubmit)}>
                <h4 className="text-center mb-4">Change Password</h4>
                <div className="row">
                    <div className="col-sm-6 mx-auto">
                        <div className="form-group has-feedback">
                            <FormFieldInput
                                attribute="oldPassword"
                                inputType="password"
                                placeholder="Enter your old password"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 6,
                                        message: 'Invalid Password (Length should be > 6)'
                                    }
                                }}
                                rest={{ maxLength: 30 }}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <FormFieldInput
                                attribute="password"
                                inputType="password"
                                placeholder="Enter your new password"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 6,
                                        message: 'Invalid Password (Length should be > 6)'
                                    }
                                }}
                                rest={{ maxLength: 30 }}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <FormFieldInput
                                attribute="confirmPassword"
                                inputType="password"
                                placeholder="Confirm your new password"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: 'Password is required',
                                    validate: value => {
                                        const vals = getValues();
                                        if (vals && vals.password && value === vals.password) {
                                            return null;
                                        }
                                        return 'Password Inconsistent';
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'Invalid Password (Length should be > 6)'
                                    }
                                }}
                                rest={{ maxLength: 30 }}
                            />
                        </div>
                        <div className="text-center">
                            <Button size="large" type="primary" onClick={handleSubmit(onSubmit)} >Reset Password</Button>
                        </div>

                    </div>
                </div>
                <div>
                </div>
            </form>
        </Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);