import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery, useMutation } from 'services/graphQL';
import { Button, Tooltip, Popconfirm, Switch } from 'antd';
import { EditOutlined, UnlockOutlined } from '@ant-design/icons';
import ManageDoctor from './manage-doctor';
import { debounce } from "lodash";
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const Doctor = (props) => {

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]],
        sequelizeJson: {
            where : {
                'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
            }
        }
    });

    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalData, setModalData] = useState({});

    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountDoctors ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountDoctors (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        specialityId
                        picture
                        fee
                        user {
                            name
                            email
                            phone
                            isAccountLocked
                            id
                            tempPassword
                        }
                        mstSpeciality { name id }
                    }
                    count
                }
            }
        `,
        {
            variables
        }
    );

    const { data: mstSpecialities, refetch: refetchMstSpecialities } = useQuery(gql`
        query {
            mstSpecialities {
                id
                name
            }
        }
    `);

    const [createDoctor, { data: createdDoctor }] = useMutation(
        gql`
          mutation createDoctor($input: DoctorCreateInput!) {
            createDoctor(input: $input) {
                id
            }
        }
      `
    );

    const [updateUser, { data: updatedUser }] = useMutation(
        gql`
          mutation updateUser($userId: ID!, $input: UserUpdateInput!) {
            updateUser(id: $userId, input: $input) {
                id
            }
        }
      `
    );

    const [updateDoctor, { data: updatedDoctor }] = useMutation(
        gql`
          mutation updateDoctor($userId: ID!, $input: DoctorCreateInput) {
            updateDoctor(id: $userId, input: $input) {
                id
            }
        }
      `
    );

    // const [delDoctor, { data: deletedData }] = useMutation(
    //     gql`
    //       mutation deleteDoctor($id: ID!) {
    //         deleteDoctor(id: $id) {
    //             id
    //         }
    //     }
    //   `
    // );

    if (createdDoctor || updatedUser || updatedDoctor) {
        refetch()
    }

    const createOrUpdate = async (event) => {
        try {
            if (!event.id) {
                const tempPassword = Math.floor(100000 + Math.random() * 900000).toString()

                event.password = tempPassword;
                event.tempPassword = tempPassword;

                const result = await window.$http.postWithoutHeaders('register/doctor', event);
                if (!result || !result.user || !result.user.id)
                    return window.$utility.showErrorMessage('User Id not found!');

                const data = {
                    userId: result.user.id,
                    picture: event.picture,
                    specialityId: +event.specialityId,
                    createdBy: props.user && props.user.clinic ? props.user.clinic.id : null,
                    fee: event.fee,
                    shouldSendCredentials: event.shouldSendCredentials
                }

                createDoctor({
                    variables: {
                        input: data
                    }
                })
            } else {
                const doctor = {
                    specialityId: +event.specialityId,
                    picture: event.picture,
                    fee: event.fee
                };
                const user = {
                    name: event.name,
                    email: event.email,
                    phone: event.phone,
                };

                event.id = parseInt(event.id);
                event.userId = parseInt(event.userId);

                updateDoctor({
                    variables: {
                        userId: event.id,
                        input: doctor
                    }
                })
                if (event.userId) {
                    updateUser({
                        variables: {
                            userId: event.userId,
                            input: user
                        }
                    })
                }
            }
        } catch (err) {
            console.log(err)
            window.$utility.showErrorMessage(err.message);
        }
    };

    // const deleteDoctor = (record) => {
    //     if (record && record.id) {
    //         delDoctor({
    //             variables: {
    //                 id: record.id
    //             }
    //         });
    //     }
    // };

    const manageDoctor = (record, mode) => {
        const result = {
            ...record,
            mode
        };
        
        if(result && result.id) {
            const doctor = data.findAndCountDoctors.rows.find(a => a.id == result.id);
            if(doctor)
                result.picture = doctor.picture
        }

        setModalData(result);
        setModalVisibility(true);
    };

    const debounceSearch =  debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        // {
                        //     'speciality': {
                        //         $iLike: `%${value}%`
                        //     }
                        // },
                        {
                            '$User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.email$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.phone$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$MstSpeciality.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ],
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                },
                include: [
                    {
                        model: 'User'
                    },
                    {
                        model: 'MstSpeciality'
                    },
                ]
            }
        });
    }, 500);

    const onClose = () => {
        setModalVisibility(false);
        setModalData({})
    };

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where : {
                    'createdBy': props.user && props.user.clinic ? props.user.clinic.id : null
                }
            }
        });
    };

    const manageSuspendSec = (event, record) => {
        if (record && record.user && record.user.id) {
            const data = {
                name: record.user.name,
                email: record.user.email,
                phone: record.user.phone,
                isAccountLocked: event
            }
            updateUser({
                variables: {
                    userId: record.user.id,
                    input: data
                }
            })
        }
    };

    const sendCrediential = async (record) => {
        try {

            if(!record || !record.user || !record.user.tempPassword)
                return window.$utility.showErrorMessage('User not found!')
            
                const data = {
                email: record.user.email,
                password: record.user.tempPassword,
                userId: record.user.id
            };

            const result = await window.$http.rawPost('user/send-crediential', data);

            window.$utility.showSucessMessage(result.message);

        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'name',
            align: 'left',
            sorter: false,
            className:'space-nowrap',
            render: (text) => (text && text.name ? text.name : 'NA'),
            width:180,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'email',
            align: 'left',
            sorter: false,
            // className:'space-nowrap',
            render: (text) => (text && text.email ? text.email : 'NA'),
            width:250,
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            key: 'phone',
            align: 'left',
            sorter: false,
            className:'space-nowrap',
            width:130,
            render: (text) => (text && text.phone ? text.phone : 'NA')
        },
        {
            title: 'Speciality',
            dataIndex: 'mstSpeciality',
            key: 'name',
            align: 'left',
            className:'space-nowrap',
            sorter: false,
            width:120,
            render: (text) => (text ? text.name : 'NA')
        },
        {
            title: 'Consulting Fee',
            dataIndex: 'fee',
            key: 'fee',
            align: 'center',
            className:'space-nowrap',
            sorter: true,
            width:120,
            render: (text) => (text ? `₹ ${text}` : 'NA'),
            showSorterTooltip: false
        },
    ];

    return (
        <Fragment>
            {/* <Button type="primary" size="large" icon={<PlusCircleOutlined />} title="Add" onClick={() => { manageDoctor({}, 'add') }} /> */}
            <DataTable
                title="Physician"
                data={data && data.findAndCountDoctors && data.findAndCountDoctors.rows ? data.findAndCountDoctors.rows : null}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={data && data.findAndCountDoctors && data.findAndCountDoctors.count ? data.findAndCountDoctors.count : 0}
                loading={loading}
                onChange={handleChange}
                notShowSearchAndAdd={false}
                createOrUpdateAction={() => { manageDoctor({}, 'add') }}
                customAction={{
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    width: 150,
                    // className:'space-nowrap',
                    render: (text, record) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title="Edit Physician">
                                <Button type="link" shape="circle" icon={<EditOutlined />} onClick={() => manageDoctor(record, 'edit')} />
                            </Tooltip>
                            <Popconfirm title="Are you sure to send credentials?" onConfirm={() => sendCrediential(record)}>
                                <Tooltip title="Send Credentials">
                                    <Button className="mr-2" type="link" shape="circle" icon={<UnlockOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={record.user && record.user.isAccountLocked ? 'Activate' : 'Dectivate'}>
                                    <Switch
                                        checked={record.user.isAccountLocked}
                                        onChange={(e) => manageSuspendSec(e, record)}
                                    />
                                </Tooltip>
                            </span>
                        </span>
                    ),
                }}
            />
            <ManageDoctor
                visible={modalVisibility}
                onClose={onClose}
                data={modalData}
                onSubmit={createOrUpdate}
                mstSpecialities={mstSpecialities}
            />
        </Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
