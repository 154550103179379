import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from "store";
import Routes from './routes';
import { graphqlClient } from 'services/graphQL';
import { useClearCache } from 'react-clear-cache';

import 'assets/css/style.scss';
import 'assets/css/common.scss';
import 'antd/dist/antd.css';

import 'services/api';
import 'services/utilities';
import 'services/lodash';


const App = () => {

    const { isLatestVersion, emptyCacheStorage } = useClearCache({ auto: true });
    console.log({isLatestVersion})

    useEffect(() => {
        const currentState = store.getState();
        if (
            currentState
            && currentState.auth
            && currentState.auth.user
            && localStorage.getItem('x-access-token')
        ) {
            validateToken()
        }
    }, [store])

    console.log('Environment', process.env.REACT_APP_ENV);

    const validateToken = async () => {
        try {
            await window.$http.get('validate-token');
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Provider store={store}>
            <ApolloProvider client={graphqlClient}>
                <Router>
                    <Routes />
                </Router>
            </ApolloProvider>
        </Provider>
    );
}

export default App;
