import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomModal from 'elements/custom-modal';
import FileUpload from 'elements/file-upload';
import FormFieldInput from 'elements/form-field-input';
import { Button } from 'antd';
import { gql, useQuery, useMutation } from 'services/graphQL';

export default (props) => {

    const { handleSubmit, setValue, errors, control } = useForm();
    const [title, setModalTitle] = useState('Patient Details');
    const [showSendCredentialsButton, setShowSendCredentialsButton] = useState(true)
    const [userId, setUserId] = useState(null)
    const [doctorImage, setDoctorImage] = useState(null);

    let mstSpecialities = props.mstSpecialities ? props.mstSpecialities.mstSpecialities : [];
    mstSpecialities = mstSpecialities.map(a => ({ label: a.name, value: a.id }));

    useEffect(() => {
        if (props.data.mode && props.data.mode === 'edit') {
            setModalTitle('Edit Physician');
            setShowSendCredentialsButton(false)
        } else {
            setModalTitle('Add Physician');
            setShowSendCredentialsButton(true)
        }
        if (props.data && props.data.id) {
            setTimeout(() => {
                setValue('name', props.data.user ? props.data.user.name : '');
                setValue('email', props.data.user ? props.data.user.email : '');
                setValue('phone', props.data.user ? props.data.user.phone : '');
                setValue('specialityId', props.data.specialityId ? props.data.specialityId.toString() : '');
                setValue('picture', props.data.picture);
                setValue('id', props.data.id);
                setValue('fee', props.data.fee);
                setUserId(props.data.user ? props.data.user.id : null)
                setDoctorImage(props.data.picture)
            });
        } else {
            setValue('name', null);
            setValue('email', null);
            setValue('phone', null);
            setValue('specialityId', undefined);
            setValue('picture', null);
            setValue('fee', null);
            setValue('shouldSendCredentials', null);
            setDoctorImage(null)

        }

    }, [props.visible, props.data]);

    const onSubmit = (data) => {
        if (props.data.mode === 'edit') {
            data.userId = userId
            data.id = props.data.id
        }
        data.picture = doctorImage;
        props.onSubmit(data)
        props.onClose();
    };

    const save = (event) => {
        setDoctorImage(event)
    }

    return (
        <CustomModal
            title={title}
            centered
            showModal={props.visible}
            width={props.width}
            onClose={props.onClose}
        >
            <div className="container">
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                    <FormFieldInput
                        inputType="text-name"
                        placeholder="Enter Physician Name"
                        attribute="name"
                        control={control}
                        setValue={setValue}
                        showSearch={true}
                        errors={errors}
                        label="Physician Name"
                        rules={{
                            required: 'Physician Name is required',
                        }}
                    />
                    <FormFieldInput
                        inputType="email"
                        placeholder="Enter Email"
                        attribute="email"
                        control={control}
                        errors={errors}
                        label="Email"
                        rules={{
                            required: 'Email is required',
                        }}
                    />
                    <FormFieldInput
                        inputType="text-number"
                        placeholder="Enter Physician Mobile Number"
                        attribute="phone"
                        control={control}
                        errors={errors}
                        label="Mobile Number"
                        maxLength={10}
                        minLength={10}
                        rules={{
                            required: 'Mobile Number is required',
                            minLength: {
                                value: 10,
                                message: 'Invalid Number (Length should be 10)'
                            }
                        }}
                    />
                    <FormFieldInput
                        inputType="text-number"
                        placeholder="Enter consultation fee"
                        attribute="fee"
                        control={control}
                        errors={errors}
                        label="Consultation fee"
                        rules={{
                            required: 'Consultation fee is required',
                        }}
                    />
                    <FormFieldInput
                        inputType="select"
                        placeholder="Select Speciality"
                        attribute="specialityId"
                        label="Select Speciality"
                        showSearch={true}
                        setValue={setValue}
                        errors={errors}
                        control={control}
                        value={props && props.data && props.data.specialityId ? props.data.specialityId.toString() : null}
                        options={mstSpecialities}
                        rules={{
                            required: 'Speciality is required',
                        }}
                    />
                    {
                        showSendCredentialsButton ? (
                            <div className="col-sm-12">
                                <label>
                                    <FormFieldInput
                                        inputType="checkbox"
                                        attribute="shouldSendCredentials"
                                        control={control}
                                        errors={errors}
                                    >Do you want to email the login details?</FormFieldInput>
                                </label>

                            </div>
                        ) : null

                    }
                    <div className="file-upload">
                        <FileUpload
                            folderName='doctor-meeting'
                            save={save}
                            image={doctorImage}
                        />
                    </div>

                    <div className="text-right">
                        <Button key="submit" type="primary" htmlType="submit">{props.data.mode === 'edit' ? 'Update' : 'Add'}</Button>
                        {/* <Button type="primary" onClick={() => props.onClose()}>Close</Button> */}
                    </div>
                </form>
            </div>
        </CustomModal>

    );
};
