import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Axios from "axios";

const FileUpload = (props) => {
    const [previewImage, setPreviewImage] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cloudinaryPreset, setPreset] = useState(process.env.REACT_APP_COUDINARY_UNSIGNED_PRESET_ID);
    const [cloudinaryURL, setURL] = useState(process.env.REACT_APP_CLOUDINARY_UPLOAD_URL);

    useEffect(() => {
        if(props) {
            setImageURL(props.image)
        }
    }, [props])

    const uploadButton = (
        <div>
            { loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Profile Upload</div>
        </div>
    );

    const handleChange = async (file) => {
        try {
            setLoading(true)
            const axios = Axios.create();
            const formData = new FormData();

            formData.append("file", file.file.originFileObj);
            formData.append("upload_preset", cloudinaryPreset);

            if (props.folderName) formData.append("folder", props.folderName);

            const result = await axios.post(cloudinaryURL, formData);

            setImageURL(result.data.secure_url);
            props.save(result.data.secure_url);
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
            window.$utility.showErrorMessage(err.message)
        }
    };

    return (
        <div className="clearfix">
            <Upload
                action=""
                listType="picture-card"
                onChange={handleChange}
                showUploadList={false}
            >
                {imageURL ? (
                    <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
                ) : (
                        uploadButton
                    )}
                {/* {fileList.length >= 8 ? null : uploadButton} */}
            </Upload>
            <Modal visible={previewImage} footer={null} >
                <img alt="example" style={{ width: '100%' }} src={imageURL} />
            </Modal>
        </div>
    )
};

FileUpload.propTypes = {
    image: PropTypes.string,
    save: PropTypes.func,
    folderName: PropTypes.string,
};

export default FileUpload
