import React, { useState, useEffect } from 'react';
import FormLayout from 'layouts/form-layout/index';
import Footer from 'layouts/form-layout/footer';
import Button from 'elements/button';
import { gql, useQuery, useMutation } from 'services/graphQL';
import MedicalEquip from "assets/images/healthcaremedical.png";
import { UserOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from 'moment'

const PaymentPage = (props) => {
    const [variables, setVariables] = useState({
        id: props.match.params.appointmentId
    });
    let razorpayResponse = {}


    const { loading, data, refetch } = useQuery(gql`
        query appointment($id: ID!){
            appointment(id: $id) {
                zoomMeetingId
                meetingDate
                patientId
                createdBy
                doctorId
                doctor { fee user { name } mstSpeciality { name } }
                patient { user { name } }
            }
        }
    `, { variables }
    );

    const onSubmit = async () => {
        try {

            const amountPaid = data.appointment ? data.appointment.doctor.fee : null
            const result = await window.$http.postWithoutHeaders('appointment/create-order', {
                amount: amountPaid
            });

            const { order } = result;
            razorpayResponse = order;

            const razorpayConfig = {
                amount: (order.amount * 100),
                key: process.env.REACT_APP_RAZORPAY_API_KEY,
                order_id: order.id,
                currency: "INR",
                handler: handleRazorpayResponse
            };

            const razor = new window.Razorpay(razorpayConfig);

            razor.open();
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };

    const handleRazorpayResponse = (response) => {

        const jsonData = {
            ...razorpayResponse,
            ...response
        };
        const appointmentId = parseInt(props.match.params.appointmentId)

        const razorpayOrderData = {
            clinicId: data.appointment.createdBy,
            doctorId: data.appointment.doctorId,
            paymentBy: data.appointment.patientId,
            razorOrderId: razorpayResponse.id,
            appointmentId: appointmentId,
            razorPaymentId: response.razorpay_payment_id,
            amount: data.appointment ? data.appointment.doctor.fee.toString() : '',
            razorResponse: JSON.stringify(jsonData)
        };
        createOrder(razorpayOrderData);
    }

    const createOrder = async (data) => {
        try {
            const result = await window.$http.postWithoutHeaders('order', data)
            if (!result)
                return window.$utility.showErrorMessage('SOmething went wrong!');

            props.history.push('/appointment/payment-success');

        } catch (err) {
            console.log(err)
            window.$utility.showErrorMessage(err.message);
        }
    }

    return (
        <div className="payment">
            <FormLayout>
                <div className="container-fluid">
                    <span className="login100-form-title pb-3 pt-1">Payment</span>
                    <div className="row">
                        <div className="col-sm-12 bg-white">
                            <div className="cu-tion pb-3 d-flex">
                                <div className="icon">
                                    <UserOutlined style={{ color: '#58c5c4', fontSize: '40px' }} />
                                </div>
                                <div className="data ml-3">
                                    <span className="heading d-block">For Whom</span>
                                    <span className="p">{data && data.appointment && data.appointment.patient ? `${data.appointment.patient.user.name}` : '-'}</span>
                                </div>
                            </div>
                            <div className="cu-tion py-3 d-flex">
                                <div className="icon">
                                    <ClockCircleOutlined style={{ color: '#58c5c4', fontSize: '40px' }} />
                                </div>
                                <div className="data ml-3">
                                    <span className="heading d-block">Consultation at</span>
                                    <span className="p">{moment(data && data.appointment ? data.appointment.meetingDate : Date.now()).format('DD MMM YYYY | hh:mm A')}</span>
                                </div>
                            </div>
                            <div className="cu-tion py-3 d-flex">
                                <div className="icon">
                                    <img className="icon-img" src={MedicalEquip} alt="doctor" />
                                </div>
                                <div className="data ml-3">
                                    <span className="heading d-block">Doctor</span>
                                    <span className="p">{data && data.appointment && data.appointment.doctor ? `${data.appointment.doctor.user.name}` : '-'}</span>
                                </div>
                            </div>
                            <div className="pt-3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><p className="">Consultation Fee :</p></td>
                                            <td><p className="">{data && data.appointment && data.appointment.doctor ? `₹ ${data.appointment.doctor.fee}` : '-'}</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="container-login100-form-btn">
                                <Button type="danger" size="large" onClick={() => onSubmit()}>Proceed to Pay</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer type="register" />
            </FormLayout>
        </div>
    );
}


export default PaymentPage;
