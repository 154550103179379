import {
    Dashboard,
    ChangePassword,
    Ledger
} from 'pages/doctor/component';

const url = '/physician';

export default [
    {
        url: `${url}/dashboard`,
        name: 'Dashboard',
        component: Dashboard
    },
    {
        url: `${url}/change-password`,
        name: 'Change Password',
        component: ChangePassword
    },
    {
        url: `${url}/ledger`,
        name: 'Ledger Management',
        component: Ledger
    },
];
