import React from 'react';
import { MessageOutlined, StockOutlined } from '@ant-design/icons';

export default {
    items: [
        {
            title: 'Appointments',
            url: 'dashboard',
            icon: <MessageOutlined />,
        },
        // {
        //     title: 'Ledger',
        //     url: 'ledger',
        //     icon: <StockOutlined />,
        // },
    ],
};
