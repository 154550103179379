import React from 'react';
import doctorlogo from 'assets/images/doctor-icon.png';

export default (props) => {
    return (
        <div>
            <div className="limiter for-mobiles form-bg-layout">
                <div className="form-overlay"></div>
                <div className="container-login100 ">
                    <div className="login-cust-form">
                        <div className="wrap-login100">
                            <div className="login100-form has-form-layout">
                                <div className="text-center">
                                    <img className="form-logo" src={doctorlogo} alt="logo"/>
                                </div>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
