import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button } from 'antd';
import FormFieldInput from 'elements/form-field-input';
import { gql, useMutation } from 'services/graphQL';
import { updateUser } from 'store/auth/action';


const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {
        update: (payload) => {
            dispatch(updateUser(payload))
        }
    }
}

const Profile = (props) => {

    const [updateUser, { data: mutationData }] = useMutation(
        gql`
            mutation updateUser($id: ID!, $userCreateInput: UserUpdateInput!) {
                updateUser(id: $id, input: $userCreateInput) {
                    id
                }
            }
        `);

    const [updatePractiseAdmin, { data: mutationPractiseAdminData }] = useMutation(
        gql`
            mutation updatePractiseAdmin($id: ID!, $practiseAdminCreateInput: PractiseAdminCreateInput!) {
                updatePractiseAdmin(id: $id, input: $practiseAdminCreateInput) {
                    id
                }
            }
        `);

    let defaultValues;
    if (props.user) {
        defaultValues = props.user;
    }

    if (mutationData && mutationPractiseAdminData) {
        window.$utility.showSucessMessage('Details are updated');
        props.history.push('/clinic-admin/manage-appointment');
    }

    const { handleSubmit, register, errors, control } = useForm({
        defaultValues
    });

    useEffect(() => {
        register({ name: "id" });
    }, [register]);

    const onSubmit = async (data) => {
        try {
            data.clinic.id = props.user.clinic.id
            let { id, clinic, ...vals } = data;

            const result = await updateUser({
                variables: {
                    userCreateInput: {
                        ...vals
                    },
                    id
                }
            });

            if (result) {
                props.update({ ...props.user, ...data });

                const clinicData = {
                    clinicName: clinic.clinicName || props.user.name,
                    physicianName: clinic.physicianName || props.user.clinic.physicianName,
                    address: clinic.address || props.user.clinic.address,
                    country: clinic.country || props.user.clinic.country
                }
                id = props.user.clinic.id;
                await updatePractiseAdmin({
                    variables: {
                        id,
                        practiseAdminCreateInput: {
                            ...clinicData
                        },
                    }
                })
            }

        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    };

    return (
        <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 mx-auto">
                        <div className="row">
                            <div className="col-sm-12 text-left ">
                                <FormFieldInput
                                    inputType="text"
                                    placeholder="Enter Clinic name"
                                    attribute="name"
                                    control={control}
                                    errors={errors}
                                    label="Name"
                                    rules={{
                                        required: 'Clinic name is required',
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 text-left">
                                <FormFieldInput
                                    inputType="text-number"
                                    placeholder="Enter Phone number"
                                    attribute="phone"
                                    control={control}
                                    errors={errors}
                                    label="Phone number"
                                    maxLength={10}
                                    minLength={10}
                                    rules={{
                                        required: 'Phone number is required',
                                        minLength: {
                                            value: 10,
                                            message: 'Invalid Number (Length should be 10)'
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 text-left ">
                                <FormFieldInput
                                    inputType="text"
                                    placeholder="Enter Address"
                                    attribute="clinic.address"
                                    control={control}
                                    errors={errors}
                                    label="Address"
                                    rules={{
                                        required: 'Address is required',
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 text-left ">
                                <div className="row">
                                    <div className="col-sm-6 text-left ">
                                        <FormFieldInput
                                            inputType="text"
                                            placeholder="Enter Physician name"
                                            attribute="clinic.physicianName"
                                            label="Physician Name"
                                            showSearch={true}
                                            errors={errors}
                                            control={control}
                                            rules={{
                                                required: 'Physician name is required!',
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6 text-right ">
                                        <FormFieldInput
                                            inputType="email"
                                            placeholder="Enter Email"
                                            attribute="email"
                                            control={control}
                                            errors={errors}
                                            label="Email"
                                            rules={{
                                                required: 'Email is required',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 text-left ">
                                <FormFieldInput
                                    inputType="select"
                                    placeholder="Select Country"
                                    attribute="clinic.country"
                                    showSearch={true}
                                    errors={errors}
                                    label="Country"
                                    control={control}
                                    options={window.$utility.getCountries()}
                                    rules={{
                                        required: 'Country is required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <Button key="submit" onClick={handleSubmit(onSubmit)} type="primary">Submit</Button>
            </div>
        </form>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
