import React from 'react';
import { connect } from 'react-redux';
import { login } from 'store/auth/action';
import { useForm } from 'react-hook-form';
import FormLayout from 'layouts/form-layout';
import Footer from 'layouts/form-layout/footer';
import LinkInline from 'elements/link-inline';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import FormFieldInput from 'elements/form-field-input';
import Button from 'elements/button';

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => {
            dispatch(login(payload))
        }
    }
}

const Login = (props) => {

    const defaultValues = {
        email: '',
        password: '',
        rememberEmail: false,
    };

    const { handleSubmit, control, errors } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        try {
            const result = await window.$http.postWithoutHeaders('login', {
                email: data.email,
                password: data.password,
            });

            if (data.rememberEmail) {
                localStorage.setItem('user_email', data.email);
            } else {
                localStorage.setItem('user_email', '');
            }

            // save user in redux
            props.login(result);

            if (
                result
                && result.user
                && result.user.userRole
                && result.user.userRole.name
                && result.user.userRole.name === 'doctor'
            ) {
                props.history.push(`/physician/dashboard`);
            }
            else{
                props.history.push(`/${result.user.userRole.name}/`);
            }
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };
    return (
        <FormLayout>
            <form className="login100-form validate-form" onSubmit={handleSubmit(onSubmit)}>
                <span className="login100-form-title">Sign In</span>
                <div className="form-group has-feedback">
                    <FormFieldInput
                        attribute="email"
                        inputType="email"
                        errors={errors}
                        control={control}
                        placeholder="Enter your email"
                        rules={{
                            required: 'Email is required'
                        }}
                        rest={{ maxLength: 50 }}
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                    />
                </div>
                <div className="form-group has-feedback">
                    <FormFieldInput
                        attribute="password"
                        inputType="password"
                        placeholder="Enter your password"
                        control={control}
                        errors={errors}
                        rules={{
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Invalid Password (Length should be > 6)'
                            }
                        }}
                        rest={{ maxLength: 30 }}
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                    />
                </div>
                <div className="col-xs-8">
                    <div className="checkbox icheck">
                        <label>
                            <FormFieldInput
                                inputType="checkbox"
                                attribute="rememberEmail"
                                control={control}
                                errors={errors}
                            >Remember Me</FormFieldInput>
                        </label>
                        <LinkInline
                            inputClass="pull-right"
                            route="forgot-password"
                        ><i className="fa fa-lock"></i> Forgot Password ?</LinkInline>
                    </div>
                </div>
                <div className="container-login100-form-btn">
                    <Button type="danger" size="large" onClick={handleSubmit(onSubmit)}>Log in</Button>
                    {/* <button className="login100-form-btn" disabled={!this.state.email || this.state.password}>Log in</button> */}
                </div>
                <div>
                    <p className="text-center  mt-2 mb-0">
                        Don't have an account? 
                        <span className="ml-1 font-weight-bold">
                            <LinkInline label="Sign up here!" route="/clinic/register" />
                        </span>
                    </p>
                </div>
                {/* <Footer /> */}
            </form>
        </FormLayout>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
