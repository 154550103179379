import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery, useMutation } from 'services/graphQL';
import { Button, Tooltip, Popconfirm, Switch } from 'antd';
import { UsergroupDeleteOutlined, MedicineBoxOutlined, PlusCircleOutlined, StockOutlined } from '@ant-design/icons';
import { debounce } from "lodash";

const ClinicList = (props) => {

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]]
    });


    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountPractiseAdmins ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountPractiseAdmins (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        clinicName
                        physicianName
                        country
                        user {
                            id
                            email
                            phone
                            isSmsAllowed
                            isPaymentAllowed
                        }
                        mstSpeciality {
                            name
                        }
                    }
                    count
                }
            }
        `,
        {
            variables
        }
    );

    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        {
                            'clinicName': {
                                $iLike: `%${value}%`
                            }
                        },
                        {
                            'physicianName': {
                                $iLike: `%${value}%`
                            }
                        },
                        {
                            'country': {
                                $iLike: `%${value}%`
                            }
                        },
                        {
                            '$User.name$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.email$': {
                                $iLike: `%${value}%`
                            },
                        },
                        {
                            '$User.phone$': {
                                $iLike: `%${value}%`
                            },
                        },
                    ]
                },
                include: [
                    {
                        model: 'User'
                    },
                ]
            }
        });
    }, 500);

    const [updateUser, { data: updatedUser }] = useMutation(
        gql`
          mutation updateUser($userId: ID!, $input: UserUpdateInput!) {
            updateUser(id: $userId, input: $input) {
                id
            }
        }
      `
    );

    if(updatedUser) {
        refetch()
    }

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'descend' ? 'DESC' : 'ASC']))
        });
    };

    const goToAppointments = (data) => {
        props.history.push(`appointment-list/${data.id}`);
    };

    const goToDoctors = (data) => {
        props.history.push(`physician-list/${data.id}`);
    };

    const goToPatients = (data) => {
        props.history.push(`patient-list/${data.id}`);
    };

    const goToLedger = (data) => {
        props.history.push(`/admin/ledger-detail/${data.id}`);
    };

    const updateUserSMS = (value, record) => {
        try {
            const userData = {
                isSmsAllowed: value,
                email: record.user.email,
                phone: record.user.phone,
                name: record.clinicName,
            }
            updateUser({
                variables: {
                    userId: record.user.id,
                    input: userData
                }
            })
        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const updateUserPayment = (value, record) => {
        try {
            const userData = {
                isPaymentAllowed: value,
                isSmsAllowed: record.user.isSmsAllowed,
                email: record.user.email,
                phone: record.user.phone,
                name: record.clinicName,
            }
            updateUser({
                variables: {
                    userId: record.user.id,
                    input: userData
                }
            })
        } catch (err) {
            window.$utility.showErrorMessage(err.message)
        }
    }

    const columns = [
        {
            title: 'Clinic Name',
            dataIndex: 'clinicName',
            key: 'clinicName',
            align: 'left',
            sorter: true,
            className: 'space-nowrap',
            render: (text) => (text ? text : 'NA'),
            width: 180,
            showSorterTooltip: false,
        },
        {
            title: 'Physician Name',
            dataIndex: 'physicianName',
            key: 'physicianName',
            align: 'left',
            sorter: true,
            className: 'space-nowrap',
            render: (text) => (text ? text : 'NA'),
            width: 180,
            showSorterTooltip: false,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'email',
            align: 'left',
            sorter: false,
            // className:'space-nowrap',
            render: (text) => (text && text.email ? text.email : 'NA'),
            width: 250,
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            key: 'phone',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            width: 130,
            render: (text) => (text && text.phone ? text.phone : 'NA')
        },
    ];

    return (
        <Fragment>
            {/* <Button type="primary" size="large" icon={<PlusCircleOutlined />} title="Add" onClick={() => { manageDoctor({}, 'add') }} /> */}
            <DataTable
                title="Doctor"
                data={data && data.findAndCountPractiseAdmins && data.findAndCountPractiseAdmins.rows ? data.findAndCountPractiseAdmins.rows : null}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={data && data.findAndCountPractiseAdmins && data.findAndCountPractiseAdmins.count ? data.findAndCountPractiseAdmins.count : 0}
                loading={loading}
                notShowSearchAndAdd={true}
                onChange={handleChange}
                customAction={{
                    title: 'Action',
                    key: 'action',
                    align: 'center',
                    width: 150,
                    // className:'space-nowrap',
                    render: (text, record) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title="View Physicians">
                                <Button type="link" shape="circle" icon={<MedicineBoxOutlined />} onClick={() => goToDoctors(record)} />
                            </Tooltip>
                            <Tooltip title="View Appointments">
                                <Button type="link" shape="circle" icon={<UsergroupDeleteOutlined />} onClick={() => goToAppointments(record)} />
                            </Tooltip>
                            <Tooltip title="View Patients">
                                <Button type="link" shape="circle" icon={<PlusCircleOutlined />} onClick={() => goToPatients(record)} />
                            </Tooltip>
                            {
                                record.user.isPaymentAllowed ? <Tooltip title="View Ledger">
                                    <Button type="link" shape="circle" icon={<StockOutlined />} onClick={() => goToLedger(record)} />
                                </Tooltip> : null
                            }
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={record.user && record.user.isSmsAllowed ? 'Deactivate SMS' : 'Activate SMS'}>
                                    <Switch
                                        checked={record.user.isSmsAllowed}
                                        onChange={(e) => updateUserSMS(e, record)}
                                    />
                                </Tooltip>
                            </span>
                            <span className="ml-2" style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={record.user && record.user.isPaymentAllowed ? 'Deactivate Payment' : 'Activate Payment'}>
                                    <Switch
                                        checked={record.user.isPaymentAllowed}
                                        className="success"
                                        onChange={(e) => updateUserPayment(e, record)}
                                    />
                                </Tooltip>
                            </span>
                        </span>
                    ),
                }}
            />
        </Fragment>
    )
};

export default ClinicList;
