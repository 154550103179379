import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomModal from 'elements/custom-modal';
import FormFieldInput from 'elements/form-field-input';
import { Button, Collapse, Timeline, Tag } from 'antd';
import moment from 'moment';
const { Panel } = Collapse;

export default (props) => {
    const defaultValues = {
        id: null,
        doctorId: undefined,
        patientId: undefined,
        date: '',
        time: '',
        isOnline: 'online'
    };

    const loggedInUser = props.userId;
    let doctors = props.doctors ? props.doctors.map(d => ({ label: d.user.name, value: d.id, createdBy: d.createdBy })) : [];
    let patients = props.patients ? props.patients.map(p => ({ label: p.user.name, value: p.id, createdBy: p.createdBy })) : [];
    const mstAppointmentTypes = props.mstAppointmentTypes ? props.mstAppointmentTypes.map(p => ({ label: p.name, value: p.id })) : [];
    let appointments = props.appointments;

    if (loggedInUser) {
        doctors = doctors && doctors.length ? doctors.filter(a => a.createdBy == loggedInUser) : []
        patients = patients && patients.length ? patients.filter(a => a.createdBy == loggedInUser) : []
        appointments = appointments && appointments.length ? appointments.filter(a => a.createdBy == loggedInUser) : []
    }

    const { handleSubmit, register, setValue, errors, reset, control } = useForm({
        defaultValues
    });

    const [title, setModalTitle] = useState('Add Appointment');
    const [doctorMeetings, setDoctorMeetings] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [getMinuteStep, setMinuteStep] = useState(15);
    const [ifIsOnline, setIfIsOnline] = useState(true)

    useEffect(() => {
        register({ name: "id" });
    }, []);

    useEffect(() => {
        setModalTitle('Add Appointment');
        reset(defaultValues);
        if (props.data) {
            const { data } = props;
            setModalTitle(data.mode == 'add' ? 'Add Appointment' : 'Edit Appointment');

            const date = moment(data.startDate).format('YYYY-MM-DD');
            const time = data.startTime ? moment(data.startTime, 'HH:mm:ss') : '';

            setSelectedDoctor(data.doctorId);
            setSelectedDate(date);
            setTimeout(() => {
                setValue('id', data.id);
                setValue('doctorId', data.doctorId && data.doctorId.toString());
                setValue('patientId', data.patientId && data.patientId.toString());
                setValue('appointmentTypeId', data.appointmentTypeId && data.appointmentTypeId.toString());
                setValue('isPaymentRequired', data.isPaymentRequired);
                setValue('date', moment(date));
                // setValue('meetingDate', moment(date));
                setValue('time', time);
                getMeetingList({}, date, data.doctorId);
                setValue('isOnline', data.isOnline ? 'online' : 'offline')
            });

            setIfIsOnline(data.isOnline)
            
        } else {
            setDoctorMeetings([]);
            setSelectedDoctor(null);
            setSelectedDate(null);
            setValue('isOnline', 'online')
            setIfIsOnline(true)
        }
    }, [props.visible, props.data]);


    const onSubmit = (data) => {
        data.id = props.data && props.data.id;
        data.zoomMeetingId = props.data && props.data.zoomMeetingId;
        data.isOnline = data.isOnline === 'online' ? true : false;

        props.onSubmit(data);
        props.onClose();
    };

    const getMeetingList = (event, date, doctor = null) => {
        setSelectedDate(date)
        const doctorId = selectedDoctor ? selectedDoctor : doctor
        if (!doctorId) return
        setDoctorMeetings([]);

        const list = appointments.filter(a => moment(a.startDate).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') && a.doctorId == doctorId);
        setDoctorMeetings(list);
    };

    const DoctorMeetingList = () => {
        let doctor = doctors.find(a => a.value == selectedDoctor);
        doctor = doctor ? `for ${doctor.label}` : null

        if (!doctorMeetings.length) return (
            <Collapse className="appointment_status">
                <Panel defaultActiveKey={['1']} header={
                    // `Appointments on ${moment(selectedDate || Date.now()).format('dddd, MMM DD')} ${doctor ? doctor : ''}`
                    <span>Appointments on <b>{moment(selectedDate || Date.now()).format('dddd, MMM DD')}</b> <b>{doctor ? doctor : ''}</b></span>
                } key="1">
                    <Timeline>
                        <Timeline.Item key="1">
                            No Appointments found!
                        </Timeline.Item>
                    </Timeline>
                </Panel>
            </Collapse>
        )

        const list = doctorMeetings.map(a => {
            return (
                <Timeline.Item key={a.id}>
                    <p className="mb-0 text-left"> <span className="appointment_time">{moment(`2020-02-01 ${a.startTime}`).format('hh:mm A')} </span>
                        <span className="ml-2">{getPatient(a.patientId)}</span>
                    </p>
                </Timeline.Item>
            )
        })

        return (
            <Collapse defaultActiveKey={['1']} accordion={false} className="appointment_status">
                <Panel header={
                    <span>Appointments on <b>{moment(selectedDate).format('dddd, MMM DD')}</b> <b>{doctor ? doctor : ''}</b></span>
                } key="1">
                    <Timeline>
                        {list}
                    </Timeline>
                </Panel>
            </Collapse>
        )
    };

    const getPatient = (id) => {
        const patient = patients.find(a => a.value == id);
        return patient ? patient.label : 'NA';
    }

    const handleDoctorChange = (event) => {
        setSelectedDoctor(event);
        getMeetingList({}, selectedDate, event)
    }
    const handleModleClose = () => {
        setSelectedDoctor(null);
        setDoctorMeetings([])
        return props.onClose()
    }

    const addPhysicianButton = (
        !doctors.length ? (<div className="float-center">
            <Button type="primary" onClick={() => props.history.push('/clinic-admin/physician')} >Add New Physician</Button>
        </div>) : (<div className="float-center"> <span> No Data </span></div>)
    );

    const addPatientButton = (
        !patients.length ? (<div className="float-center">
            <Button type="primary" onClick={() => props.history.push('/clinic-admin/patient')} >Add New Patient</Button>
        </div>) : (<div className="float-center"> <span> No Data </span></div>)
    )

    const handleOnlineChange = (value) => {
        setIfIsOnline(value === 'online' ? true : false)
    }

    return (
        <CustomModal
            title={title}
            centered
            showModal={props.visible}
            width="500px"
            onClose={handleModleClose}
        >
            <div className="container">
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-sm-12">
                        <label>
                            <FormFieldInput
                                inputType="radio-group"
                                attribute="isOnline"
                                label="Whether Online or Offline"
                                register={register}
                                showSearch={true}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                onChange={handleOnlineChange}
                                value={'online'}
                                options={[{ label: 'Online', value: 'online' }, { label: 'In-Clinic', value: 'offline' }]}
                            />
                        </label>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <FormFieldInput
                                inputType="select"
                                placeholder="Select Physician"
                                attribute="doctorId"
                                showSearch={true}
                                label="Select Physician"
                                register={register}
                                onChange={(event) => handleDoctorChange(event)}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                notFoundContent={addPhysicianButton}
                                value={props && props.data && props.data.doctorId ? props.data.doctorId : null}
                                options={doctors}
                                rules={{
                                    required: 'Physician is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormFieldInput
                                inputType="date-picker"
                                placeholder="Select appointment date"
                                attribute="date"
                                register={register}
                                setValue={setValue}
                                onChange={getMeetingList}
                                errors={errors}
                                control={control}
                                format='MMM DD, YYYY'
                                label="Appointment Date"
                                style={{ "width": "100%" }}
                                rules={{
                                    required: 'Appointment Date is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormFieldInput
                                inputType="time-picker"
                                placeholder="Select appointment Time"
                                attribute="time"
                                register={register}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                minuteStep={getMinuteStep}
                                label="Appointment Time"
                                rest={{
                                    format: 'HH:mm',
                                    minuteStep: 15
                                }}
                                // format="hh:mm a"
                                style={{ "width": "100%" }}
                                rules={{
                                    required: 'Appointment Time is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            <FormFieldInput
                                inputType="select"
                                placeholder="Select Appointment Type"
                                attribute="appointmentTypeId"
                                label="Select Appointment Type"
                                register={register}
                                showSearch={true}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                value={props && props.data && props.data.appointmentTypeId ? props.data.appointmentTypeId.toString() : null}
                                options={mstAppointmentTypes}
                                rules={{
                                    required: 'Appointment type is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            <FormFieldInput
                                inputType="select"
                                placeholder="Select Patient"
                                attribute="patientId"
                                label="Select Patient"
                                register={register}
                                showSearch={true}
                                setValue={setValue}
                                errors={errors}
                                control={control}
                                value={props && props.data && props.data.patientId ? props.data.patientId.toString() : null}
                                options={patients}
                                notFoundContent={addPatientButton}
                                disabled={props.disablePatient}
                                rules={{
                                    required: 'Patient is required',
                                }}
                            />
                        </div>
                        <div className="col-sm-12 mb-3">
                            <DoctorMeetingList />
                        </div>
                    </div>
                    <div className="text-right">
                        <Button key="submit" type="primary" htmlType="submit">Schedule</Button>
                    </div>
                    {
                        props.isPaymentAllowed && ifIsOnline ? <div className="col-sm-12">
                            <label>
                                <FormFieldInput
                                    inputType="checkbox"
                                    attribute="isPaymentRequired"
                                    control={control}
                                    errors={errors}
                                >Should collect payment?</FormFieldInput>
                            </label>

                        </div> : null
                    }

                </form>
            </div>
        </CustomModal>
    );
};
