import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'elements/data-table';
import { gql, useQuery, useMutation } from 'services/graphQL';
import { debounce } from "lodash";
import moment from 'moment';
import { Button, Tooltip, Popconfirm, Switch } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import ManageNote from './manage-note';

const mapStateToProps = (state) => ({ user: state && state.auth && state.auth.user ? state.auth.user : null });

const mapDispatchToProps = (dispatch) => {
    return {}
}

const Ledger = (props) => {

    const [ledgerData, setLedgerData] = useState({
        rows: [],
        count: 0
    })
    const [modalData, setModalData] = useState({});
    const [modalVisibility, setModalVisibility] = useState(false);

    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["id", "DESC"]]
    });

    const [noteVariable, setNoteVariable] = useState({
        sequelizeJson: {
            where: {
                'clinicId': ''
            }
        }
    });
    const [clinicId, setClinicId] = useState(null)

    useEffect(() => {
        const queryParams = props.location.search;
        if (queryParams) {
            const clinicId = queryParams.split('=')[1]
            // setClinicId(clinicId)
            setSequelizeJson(clinicId);
        }
    }, [])

    const { loading, data, refetch } = useQuery(
        gql`
            query findAndCountOrders ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
                findAndCountOrders (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                    rows {
                        id
                        doctorId
                        paymentBy
                        clinicId
                        amount
                        appointmentId
                        practiseAdmin { clinicName id }
                        createdAt
                    }
                    count
                }
            }
        `,
        {
            variables
        }
    );

    const [createTransaction, { data: createdData }] = useMutation(gql`
        mutation createTransaction($inputTransaction: TransactionCreateInput!) {
            createTransaction(input: $inputTransaction) {
                id
            }
        }
    `);

    useEffect(() => {
        setTotalAmountData()
    }, [data])



    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [
                        // {
                        //     '$amount$': {
                        //         $iLike: `%${value}%`
                        //     },
                        // },
                        {
                            '$PractiseAdmin.User.name$': {
                                $iLike: `%${value}%`
                            },
                        }
                    ]
                },
                include: [
                    {
                        model: 'PractiseAdmin',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    }
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {

        if (!Array.isArray(sorter)) sorter = [sorter];
        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'descend' ? 'DESC' : 'ASC']))
        });
        setSequelizeJson()
    };

    const setSequelizeJson = (clinicId = null) => {
        if (clinicId) {
            setVariables({
                ...variables,
                sequelizeJson: {
                    where: {
                        'clinicId': clinicId
                    }
                }
            })
        }
    }

    const setTotalAmountData = () => {
        if (data && data.findAndCountOrders && data.findAndCountOrders.rows) {

            let uniqClinic = data.findAndCountOrders.rows.map(a => a.clinicId)
            uniqClinic = uniq(uniqClinic)

            let clinicTransactions = []
            for (const clinicId of uniqClinic) {
                let clinictotalAmount = data.findAndCountOrders.rows.filter(a => a.clinicId == clinicId)
                    .map(a => +a.amount).reduce((a, b) => a + b);

                clinicTransactions.push({
                    clinicId,
                    amount: clinictotalAmount.amount ? +clinictotalAmount.amount : clinictotalAmount,
                    appointmentId: data.findAndCountOrders.rows.find(a => a.clinicId == clinicId).appointmentId
                })
            };
            clinicTransactions = clinicTransactions.map(a => ({
                ...a,
                clinicName: data.findAndCountOrders.rows.find(b => b.clinicId == a.clinicId).practiseAdmin.clinicName,
                // orderId: data.findAndCountOrders.rows.find(b => b.clinicId == a.clinicId).id,
                clinicId: a.clinicId,
                appointmentId: a.appointmentId
            }));

            setLedgerData({
                rows: clinicTransactions,
                count: clinicTransactions.length
            })
        }
    };

    const createOrUpdate = (noteData) => {
        try {
            const data = {
                context: noteData.context,
                amount: noteData.amount,
                transferedOn: noteData.transferedOn,
                clinicId: noteData.clinicId,
                transactionId: noteData.transactionId,
            }

            createTransaction({ variables: { inputTransaction: data } })

        } catch (err) {
            console.log(err)
            window.$utility.showErrorMessage(err.message)
        }
    };

    const onClose = () => {
        setModalVisibility(false);
        setModalData({})
    };

    const manageNote = (data) => {
        const { clinicId, appointmentId } = data;
        setModalData({
            clinicId,
            appointmentId
        });

        setModalVisibility(true)
    }

    const goToClinicLedger = (data) => {
        const clinicId = data.clinicId ? data.clinicId : null;
        if (clinicId) {
            props.history.push(`/admin/ledger-detail/${clinicId}`);
            // setSequelizeJson(clinicId)
        }
    }

    const columns = [
        {
            title: 'Clinic',
            dataIndex: 'clinicName',
            key: 'clinicName',
            align: 'left',
            sorter: false,
            render: (text) => (text ? text : 'NA'),
            width: 150,
            className: 'space-nowrap',
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'left',
            sorter: false,
            render: (text) => (text ? `₹ ${text}` : 'NA'),
            width: 160,
            className: 'space-nowrap',
        },
    ];

    return (
        <Fragment>
            <DataTable
                title="Ledger"
                data={ledgerData && ledgerData.rows ? ledgerData.rows : []}
                columns={columns}
                debounceSearch={debounceSearch}
                totalRecords={ledgerData && ledgerData.count ? ledgerData.count : 0}
                loading={loading}
                onChange={handleChange}
                notShowSearchAndAdd={true}
                customAction={{
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    width: 80,
                    // className:'space-nowrap',
                    render: (text, record) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title="View Clinics Ledger ">
                                <Button type="link" shape="circle" icon={<EyeOutlined />} onClick={() => goToClinicLedger(record)} />
                            </Tooltip>
                            <Tooltip title="Send Note">
                                <Button type="link" shape="circle" icon={<EditOutlined />} onClick={() => manageNote(record)} />
                            </Tooltip>
                        </span>
                    ),
                }}
            />
            <ManageNote
                visible={modalVisibility}
                onClose={onClose}
                data={modalData}
                onSubmit={createOrUpdate}
            />
        </Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Ledger);
