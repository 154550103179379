import React, { useState, useEffect } from 'react';
import FormLayout from 'layouts/form-layout';
import Footer from 'layouts/form-layout/footer';
import FormFieldInput from 'elements/form-field-input';
import { UserOutlined, MailOutlined, PhoneOutlined, AimOutlined } from '@ant-design/icons';
import Button from 'elements/button';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { login } from 'store/auth/action';
import LinkInline from 'elements/link-inline';
import { gql, useQuery, useMutation } from 'services/graphQL';

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        register: (payload) => {
            dispatch(login(payload))
        }
    }
}

const Register = (props) => {
    const [mstSpecialities, setMstSpecialities] = useState([]);

    const { loading, data, refetch } = useQuery(gql`
        query {
            mstSpecialities {
            name
            id
            }
        }
    `
    );
    useEffect(() => {
        if(data && data.mstSpecialities) {
            const speciality = data.mstSpecialities.map(a => ({value: a.id, label: a.name}))
            setMstSpecialities(speciality)
        }
    }, [data])

    const defaultValues = {
        physicianName: null,
        clinicName: null,
        phone: null,
        email: null,
        specialityId: undefined,
        address: null,
        country: undefined,
        password: null,
        confirmPassword: null,
        checked: false,
        linkedInRedirectUrl: process.env.REACT_APP_LINKEDIN_REDIRECT_URL
    };

    const { handleSubmit, control, errors, getValues } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        try {
            data.name = data.clinicName

            const result = await window.$http.postWithoutHeaders('register/practiseAdmin', data);
            if (result && result.user) {
                // save user in redux
                // props.register(result);

                window.$utility.showSucessMessage('You are successfully registered. Please check your email!', 20);
                props.history.push('/login');
            }
        } catch (err) {
            window.$utility.showErrorMessage(err.message);
        }
    };

    return (
        <div className="register-form">
            <FormLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="container-fluid">
                        <span className="login100-form-title pt-1">Regsitration Form</span>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        inputType="text"
                                        placeholder="Enter physician Name"
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                                        rules={{
                                            required: 'Physician name is required'
                                        }}
                                        attribute="physicianName"
                                        control={control}
                                        errors={errors}
                                        rest={{ maxLength: 30 }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        inputType="text"
                                        placeholder="Enter Clinic Name"
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                                        rules={{
                                            required: 'Clinic name is required'
                                        }}
                                        attribute="clinicName"
                                        control={control}
                                        errors={errors}
                                        rest={{ maxLength: 30 }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        attribute="email"
                                        inputType="email"
                                        control={control}
                                        errors={errors}
                                        placeholder="Enter your email"
                                        rules={{
                                            required: 'Email is required'
                                        }}
                                        rest={{ maxLength: 50 }}
                                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        inputType="text-number"
                                        placeholder="Enter your Phone Number"
                                        attribute="phone"
                                        control={control}
                                        errors={errors}
                                        maxLength={10}
                                        minLength={10}
                                        rules={{
                                            required: 'Mobile Number is required',
                                            minLength: {
                                                value: 10,
                                                message: 'Invalid Number (Length should be 10)'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <FormFieldInput
                                        attribute="address"
                                        inputType="text"
                                        control={control}
                                        errors={errors}
                                        placeholder="Enter your Address"
                                        rules={{
                                            required: 'Address is required',
                                        }}
                                        prefix={<AimOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        inputType="select"
                                        placeholder="Select Country"
                                        attribute="country"
                                        showSearch={true}
                                        errors={errors}
                                        control={control}
                                        options={window.$utility.getCountries()}
                                        rules={{
                                            required: 'Country is required',
                                        }}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <FormFieldInput
                                        attribute="country"
                                        inputType="text"
                                        control={control}
                                        errors={errors}
                                        placeholder="Enter Country"
                                        rules={{
                                            required: 'Country is required',
                                        }}
                                        prefix={<AimOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />}
                                    />
                                </div> */}
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        inputType="select"
                                        placeholder="Select Speciality"
                                        attribute="specialityId"
                                        showSearch={true}
                                        errors={errors}
                                        control={control}
                                        options={mstSpecialities}
                                        rules={{
                                            required: 'Speciality is required',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        attribute="password"
                                        inputType="password"
                                        placeholder="Enter your password"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: 'Password is required',
                                            minLength: {
                                                value: 6,
                                                message: 'Invalid Password (Length should be > 6)'
                                            }
                                        }}
                                        rest={{ maxLength: 30 }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <FormFieldInput
                                        attribute="confirmPassword"
                                        inputType="password"
                                        placeholder="Confirm your password"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: 'Password is required',
                                            validate: value => {
                                                const vals = getValues();
                                                if (vals && vals.password && value === vals.password) {
                                                    return null;
                                                }
                                                return 'Password Inconsistent';
                                            },
                                            minLength: {
                                                value: 6,
                                                message: 'Invalid Password (Length should be > 6)'
                                            }
                                        }}
                                        rest={{ maxLength: 30 }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="checkbox icheck">
                                    <label>
                                        <FormFieldInput
                                            inputType="checkbox"
                                            attribute="checked"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                validate: value => {
                                                    if (value) {
                                                        return null;
                                                    }
                                                    return 'Please check Terms and Conditions';
                                                }
                                            }}
                                        >
                                            I agree to all <strong>Terms &amp; Conditions</strong>
                                        </FormFieldInput>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="container-login100-form-btn">
                                    <Button type="danger" size="large" onClick={handleSubmit(onSubmit)}>Register</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-center  mt-2 mb-0">
                            Already have an account?
                        <span className="ml-1 font-weight-bold">
                                <LinkInline label="Sign in here!" route="/login" />
                            </span>
                        </p>
                    </div>
                    <Footer type="register" />
                </form>
            </FormLayout>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
