import React, { useState, Fragment } from 'react';
import { gql, useQuery } from 'services/graphQL';
import moment from 'moment';
import { Tabs } from 'antd';
import { TableOutlined, CalendarOutlined } from '@ant-design/icons';
import { debounce } from "lodash";

import TableView from './components/table-view';
import CalanderView from 'elements/calander-view';

const { TabPane } = Tabs;

const AppointmentList = (props) => {
    const userId = props.match.params.userId;

    const [calanderEvents, setCalanderEvents] = useState([]);
    const [tabClasses, setTabClasses] = useState('tableview');
    const [currentView, setCurrentView] = useState('table_view');
    const [variables, setVariables] = useState({
        offset: 0,
        limit: 10,
        order: [["createdAt", "DESC"]],
        sequelizeJson: {
            where: {
                'createdBy': userId
            }
        }
    });

    const { loading, data, refetch } = useQuery(gql`
        query findAndCountAppointments ($offset: Int, $limit: Int, $order: [[String]], $sequelizeJson : SequelizeJSON) {
            findAndCountAppointments (offset: $offset, limit: $limit, order: $order, sequelizeJson: $sequelizeJson) {
                rows {
                    id
                    patientId
                    doctorId
                    patient { id user { id name phone}}
                    doctor { id user { id name }}
                    zoomMeetingId
                    meetingDate
                    startDate
                    startTime
                    createdAt
                    status
                    patientStatus
                    appointmentTypeId
                }
                count
            }
        }
    `, { variables }
    );

    const channel = window.$pusher.subscribe('meeting_conference');

    channel.bind('joining', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                refetch()
                // data.findAndCountAppointments.rows[index].patientStatus = 'online';
            }
        }
    })

    channel.bind('left', (meetingData) => {
        const { meetingId } = meetingData.message;

        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {

            const index = data.findAndCountAppointments.rows.findIndex(a => a.zoomMeetingId == meetingId);
            console.log({ index })
            if (index != -1) {
                // data.findAndCountAppointments.rows[index].patientStatus = 'offline';
                refetch()
            }
        }
    })

    const debounceSearch = debounce((value) => {
        setVariables({
            ...variables,
            sequelizeJson: {
                where: {
                    $or: [{
                        zoomMeetingId: {
                            $iLike: `%${value}%`
                        },
                    },
                    {
                        '$Patient.User.name$': {
                            $iLike: `%${value}%`
                        },
                    }, {
                        '$Doctor.User.name$': {
                            $iLike: `%${value}%`
                        },
                    }
                    ],
                    'createdBy': userId
                },
                include: [
                    {
                        model: 'Patient',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                    {
                        model: 'Doctor',
                        include: [
                            {
                                model: 'User'
                            }
                        ]
                    },
                ]
            }
        });
    }, 500);

    const handleChange = (pagination, filters, sorter, extra) => {
        if (!Array.isArray(sorter)) sorter = [sorter];

        setVariables({
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            order: sorter.map(el => ([el.field ? el.field : 'id', el.order === 'ascend' ? 'ASC' : 'DESC'])),
            sequelizeJson: {
                where: {
                    'createdBy': userId
                }
            }
        });
    };

    const columns = [
        {
            title: 'Meeting Date',
            dataIndex: 'meetingDate',
            key: 'meetingDate',
            render: text => (text ? moment(text).format('MMM DD, YYYY hh:mm A') : 'NA'),
            sorter: true,
            className: 'space-nowrap',
            width: 165,
            align: 'left',
            showSorterTooltip: false
        },
        {
            title: 'Physician',
            dataIndex: '',
            key: 'doctor.id',
            align: 'left',
            render: value => <span className="cursor-pointer space-nowrap">{value.doctor ? value.doctor.user.name : 'NA'}</span>,
            sorter: false,
            width: 150,
        }, {
            title: 'Patient',
            dataIndex: '',
            key: 'patient.id',
            render: value => <span className="cursor-pointer space-nowrap">{value.patient ? value.patient.user.name : 'NA'}</span>,
            sorter: false,
            align: 'left',
            width: 150,
        },
        {
            title: 'Patient No.',
            dataIndex: 'patient',
            key: 'user',
            align: 'left',
            sorter: false,
            className: 'space-nowrap',
            render: (text) => (text && text.user ? text.user.phone : 'NA'),
            width: 100
        },
        // {
        //     title: 'Meeting Id',
        //     dataIndex: 'zoomMeetingId',
        //     key: 'zoomMeetingId',
        //     sorter: false,
        //     className:'space-nowrap',
        //     width:120,
        //     align: 'center',
        // },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: text => (text ? moment(text).format('MMM DD, YYYY hh:mm A') : 'NA'),
            sorter: true,
            className: 'space-nowrap',
            width: 165,
            align: 'left',
            showSorterTooltip: false
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <span className="capitalise space-nowrap">{text}</span>,
            sorter: true,
            align: 'left',
            width: 90,
        },
        {
            title: 'Patient Status',
            dataIndex: 'patientStatus',
            key: 'patientStatus',
            align: 'center',
            width: 150,
            sorter: true,
            className: 'space-nowrap',
            showSorterTooltip: false,
            render: (text) => <span className={text == 'online' ? "text-success" : "text-danger"}>{capatilizeText(text)}</span>
        },
    ];

    const handleTabClick = (key) => {
        setCurrentView(key)
        if (key === 'table_view') {
            setTabClasses('tableview');
        } else if (key === 'calander_view') {
            handleCalanderEvents();
            setTabClasses('calendarview');
        }
    }

    const handleCalanderEvents = (appointmentData = {}) => {
        if (data && data.findAndCountAppointments && data.findAndCountAppointments.rows) {
            let events = data.findAndCountAppointments.rows.map(a => ({
                title: `${a.patient && a.patient.user ? a.patient.user.name : ''} - Dr. ${a.doctor && a.doctor.user ? a.doctor.user.name : ''}`,
                id: a.id,
                date: moment(a.meetingDate).format('YYYY-MM-DD'),
                textColor: '#fff',
                status: a.status
            }));

            events = events.filter(a => a.status !== 'cancelled')

            // if (appointmentData) {
            //     const doctor = users.doctors.find(a => a.id == appointmentData.doctorId)
            //     const patient = users.patients.find(a => a.id == appointmentData.patientId)

            //     const event = {
            //         title: `${patient ? patient.user.name : ''} - Dr. ${doctor ? doctor.user.name : ''}`,
            //         id: '',
            //         date: moment(appointmentData.meetingDate).format('YYYY-MM-DD'),
            //         textColor: '#fff',
            //         status: 'scheduled',
            //     }
            //     events.push(event)
            // }

            setCalanderEvents(events)
        }
    };

    const capatilizeText = (text) => {
        const name = text ? text.charAt(0).toUpperCase() + text.slice(1) : 'NA'
        return name
    };

    const tabs = [
        {
            title: 'Table View',
            icon: (<span><TableOutlined /> Table View</span>),
            content: (
                <TableView
                    appointments={data && data.findAndCountAppointments ? data.findAndCountAppointments : {}}
                    columns={columns}
                    loading={loading}
                    debounceSearch={debounceSearch}
                    handleChange={handleChange}
                />
            ),
            key: 'table_view'
        },
        {
            title: 'Calander View',
            icon: (<span><CalendarOutlined /> Calander View </span>),
            content: (
                <CalanderView
                    calanderEvents={calanderEvents}
                />
            ),
            key: 'calander_view'
        }
    ];

    return (
        <Fragment>
            <div className={tabClasses}>
                <Tabs className="appointment-tab" activeKey={currentView} onTabClick={handleTabClick} type="card">
                    {
                        tabs.map(a => (
                            <TabPane tab={a.icon} key={a.key}>
                                {a.content}
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        </Fragment>
    );
}
export default AppointmentList;
