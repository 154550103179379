import React from 'react';
import { StockOutlined, MedicineBoxOutlined } from '@ant-design/icons';

export default {
    items: [
        {
            title: 'Clinic',
            url: '/admin/clinic-list',
            icon: <MedicineBoxOutlined />,
        },
        {
            title: 'Ledger',
            url: '/admin/ledger',
            icon: <StockOutlined />,
        },
    ],
};
