import React, { Fragment } from 'react';
import DataTable from 'elements/data-table';

const Recieved = (props) => {
    const recievedData = props.data;
    const columns = props.columns;

    return (
        <Fragment>
            <DataTable
                title="Amount Recieved"
                data={recievedData && recievedData.findAndCountTransactions ? recievedData.findAndCountTransactions.rows : []}
                columns={columns}
                debounceSearch={props.debounceSearch}
                totalRecords={recievedData && recievedData.findAndCountTransactions.count ? recievedData.findAndCountTransactions.count : 0}
                loading={props.loading}
                onChange={props.handleChange}
                notShowSearchAndAdd={true}
                customAction={'null'}
            />
        </Fragment>
    )
};

export default Recieved;
