import React from 'react';

export default (props) => {

    return (
        <div>
            <h1>Welcome to Doctor Meeting Admin Portal</h1>
        </div>
    );
}

